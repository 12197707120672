(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider) {
            $stateProvider.state('app.sitemap', {
                url: '/sitemap',
                data: {
                    name: 'sitemap'
                },
                views: {
                    '@': {
                        templateUrl: 'template/views/sitemap/index.html',
                        controller: 'SitemapCtrl',
                        controllerAs: 'sitemapCtrl',
                        resolve: {
                            user: ['User', function (user) {
                                if (!user || !user.session.userId) {
                                    return user;
                                }
                                return user.getData();
                            }]
                        }
                    }
                }
            });
        }])
        .controller('SitemapCtrl', ['$scope', '$state', '$rootScope', 'User', 'Util', 'Config', 'ORGANIZATION_TYPES', 'ORGANIZATIONS_ROLES', 'SP_PAYMENTS', function ($scope, $state, $rootScope, User, Util, Config, ORGANIZATION_TYPES, ORGANIZATIONS_ROLES, SP_PAYMENTS) {
            var sitemapCtrl = this;
                sitemapCtrl.categories = [];
                sitemapCtrl.user = User;
                sitemapCtrl.getHrefFromState = getHrefFromState;
                sitemapCtrl.isActiveFaqLink = !!($rootScope.config.retailer.settings.faq && sp.frontendData.retailer.faqs.length);

                sitemapCtrl.hasCreditCardMethod = _checkHasCreditCardMethod();
                sitemapCtrl.hasPayPalMethod = _checkHasPayPalMethod();

                sitemapCtrl.isAvailableOrganization = (_isOrganizationObligoType() || _isOrganizationRegularPaymentType()) && sitemapCtrl.user.organization.roleId === ORGANIZATIONS_ROLES.ADMIN;
                sitemapCtrl.organizationTitle = _isOrganizationObligoType() ? 'Organization Budget' : 'Organization Management';
                sitemapCtrl.isAvailablePayments = sitemapCtrl.hasCreditCardMethod || sitemapCtrl.hasPayPalMethod;

                sitemapCtrl.isAvailableMembershipClub = Config.retailer.loyaltyClub && !_isOrganizationObligoType();

                var loyaltyClubDriver = !!Config.retailer.loyaltyClubDrivers && !!Config.retailer.loyaltyClubDrivers.length === 1 && Config.retailer.loyaltyClubDrivers[0];
                sitemapCtrl.membershipClubState = (!!loyaltyClubDriver && loyaltyClubDriver.clientConfig.extendedLoyaltyClub) ? 'extendedLoyalty' : 'loyalty';
                sitemapCtrl.isAvailableEmployeeDiscount = Config.retailer.settings.discountClubActive && !_isOrganizationObligoType();
                sitemapCtrl.isInternalUrl = isInternalUrl;

                Config.initPromise.then(function() {
                    sitemapCtrl.categories.push.apply(sitemapCtrl.categories, Config.tree.categories);

                });

                function getHrefFromState(state, params) {
                    return $state.href(state, params);
                }

                function _isOrganizationObligoType() {
                    return sitemapCtrl.user && sitemapCtrl.user.organization && sitemapCtrl.user.organization.organizationType === ORGANIZATION_TYPES.OBLIGO;
                }
    
                function _isOrganizationRegularPaymentType() {
                    return sitemapCtrl.user && sitemapCtrl.user.organization && sitemapCtrl.user.organization.organizationType === ORGANIZATION_TYPES.NO_OBLIGO;
                }

                function _checkHasCreditCardMethod() {
                    return !!angular.filterCollection(Config.retailer.checkoutPaymentMethods, function (paymentMethod) {
                        return (paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.CREDIT_CARD && Config.creditCardConfig && Config.creditCardConfig.mode === 'TOKEN');
                    }).length;
                }

                function _checkHasPayPalMethod() {
                    return !!sitemapCtrl.user && !!sitemapCtrl.user.paypalAccounts && !!sitemapCtrl.user.paypalAccounts.length && !!angular.filterCollection(Config.retailer.checkoutPaymentMethods, function (paymentMethod) {
                        return paymentMethod.name === SP_PAYMENTS.PAYMENT_METHODS.NAMES.PAYPAL;
                    }).length;
                }

                function isInternalUrl(url) {
                    if (url.startsWith('/')) {
                        url = location.protocol + '//' + location.host + url;
                    }
                    return new URL(url).origin == location.origin;
                }
        }]);
})(angular, app);