(function (angular, app) {
    'use strict';

    app.controller('LoginCtrl', ['$rootScope', '$scope', '$location', '$timeout', '$window', '$filter', 'Config', 'Util', 'Api', 'User', 'SpDialogUrlManager', 'UserVerificationDialog', 'FormCompletionDialog', 'Toast', 'Cart', 'RETAILER_STATUSES', 'SpCaptcha', 'DataLayer', 'LocalStorage', 'Util',
        function ($rootScope, $scope, $location, $timeout, $window, $filter, config, util, Api, user, SpDialogUrlManager, UserVerificationDialog, FormCompletionDialog, toast, cart, RETAILER_STATUSES, SpCaptcha, DataLayer, localStorage, Util) {
            var loginCtrl = this,
                loginOrRegisterCtrl = $scope.loginOrRegisterCtrl,
                otpOptionSelected,
                otpPhoneOrEmail,
                otpCodeInputs = [],
                showInvalidOrExpiredText = false,
                showAccountSuspended = false,
                LoginWithOtpOptions = $rootScope.LOGIN_WITH_OTP_OPTIONS,
                LoginOrRegisterScreens = $rootScope.LOGIN_OR_REGISTER_SCREENS,
                UserTypeForCompletionForm = $rootScope.USER_TYPE_FOR_COMPLETION_FORM,
                PhoneTypes = $rootScope.PHONE_TYPES,
                numberOfDigitsForOTPCode = [],
                _translate = $filter('translate'),
                _masked = $filter('masked');
            loginCtrl.otpOptionSelectedMapObject = {};
            loginCtrl.otpOptionSelectedMapObject[LoginWithOtpOptions.PHONE_NUMBER] = {
                img: 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/otp-phone.png',
                enterOtpPhoneOrEmailTitle: 'Please enter your phone number',
                enterCodeToValidateTitle: 'We\'ve sent a Password to the mobile number',
                enterCodeToValidateSmallText: 'not your phone number?',
                otpErrorTitle: 'Sorry, we cannot proceed using this phone number'
            };
            loginCtrl.otpOptionSelectedMapObject[LoginWithOtpOptions.EMAIL] = {
                img: 'https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/otp-email.png',
                enterOtpPhoneOrEmailTitle: 'Please enter your Email',
                enterCodeToValidateTitle: 'We\'ve sent a Password to your email address at',
                enterCodeToValidateSmallText: 'not your email?',
                otpErrorTitle: 'Sorry, we cannot proceed using this email'
            };

            angular.extend(loginCtrl, {
                config: config,
                showCaptcha: false,
                showResetCaptcha: true,
                captchaIsInvalid: false,
                captchaMessage: '',
                loginAttempts: $rootScope,
                LoginWithOtpOptions: LoginWithOtpOptions,
                LoginOrRegisterScreens: LoginOrRegisterScreens,
                otpOptionSelected: otpOptionSelected,
                otpPhoneOrEmail: otpPhoneOrEmail,
                otpCodeInputs: otpCodeInputs,
                numberOfDigitsForOTPCode: numberOfDigitsForOTPCode,
                showInvalidOrExpiredText: showInvalidOrExpiredText,
                showAccountSuspended: showAccountSuspended,
                otpAttempts: 0,
                login: login,
                filterNonDigits: filterNonDigits,
                filterNonDigitsOnChange: filterNonDigitsOnChange,
                handlePasteEvent: handlePasteEvent,
                loginWithFacebook: loginWithFacebook,
                showLoginWithOtp: showLoginWithOtp,
                showEnterSmsOrMail: showEnterSmsOrMail,
                sendOneTimeCode: sendOneTimeCode,
                validateOtpCode: validateOtpCode,
                goToEnterOtp: goToEnterOtp,
                setFocus: setFocus,
                showForgotPassword: showForgotPassword,
                getForgotPasswordCode: getForgotPasswordCode,
                savePassword: savePassword,
                resendActivationLink: resendActivationLink,
                backToLogin: backToLogin,
                backClose: backClose,
                getValidLoginScreenBasedOnBackendConfiguration: getValidLoginScreenBasedOnBackendConfiguration,
                changeView: changeView,
                addEventListenersToOtpCodeInputsForBackspaceKey: addEventListenersToOtpCodeInputsForBackspaceKey,
                openFormCompletionDialog: openFormCompletionDialog
            });

            getValidLoginScreenBasedOnBackendConfiguration();

            var search = $location.search();
            if (search.email && search.resetPasswordCode) {
                _validateResetCode()
                    .then(function () {
                        if (!loginCtrl.isResetCodeError) {
                            loginCtrl.changeView(LoginOrRegisterScreens.RESET_PASSWORD);
                            loginCtrl.resetData = {
                                email: search.email,
                                code: search.resetPasswordCode
                            };
                        } else {
                            loginCtrl.changeView(LoginOrRegisterScreens.RESET_PASSWORD_ERROR);
                        }
                    })
            }

            SpCaptcha.countFormSubmits(loginCtrl);

            if (search.registrationTab && loginOrRegisterCtrl.tabs[1]) {
                loginOrRegisterCtrl.show(loginOrRegisterCtrl.tabs[1]);
                loginOrRegisterCtrl.user.email = search.registrationEmail || '';
            }

            if (search.token && search.id) {
                user.loginWithToken(search.id, search.token, null, search.otpType)
                    .then(function (response) {
                        if (!response.message || !response.message.includes('foreignId not found')) {
                            userLoggedInSuccessfully(response);
                        }
                    })
                    .catch(function (err) {
                        errorOccuredWhileUserLoggingIn(err);
                    });
                $location.search('token', null);
                $location.search('id', null);
                $location.search('loginOrRegister', null);
            }

            DataLayer.push(DataLayer.EVENTS.VIEW_LOGIN);

            function getValidLoginScreenBasedOnBackendConfiguration() {
                if (config.retailer.settings.isLoginViaOtpActive === 'true' && loginCtrl.config.retailer.settings.numberOfDigitsForOTP) {
                    loginCtrl.numberOfDigitsForOTPCode = new Array(+loginCtrl.config.retailer.settings.numberOfDigitsForOTP);
                }

                if (config.retailer.settings.mainLoginPage === 'otp-login' && config.retailer.settings.isLoginViaOtpActive === 'true') {
                    var isEnablePremiumLoyaltyFeature = Util.isLoyaltyPremiumPackageEnabled()

                    if (config.retailer.settings.allowLoginByEmailVerification === 'true' && config.retailer.settings.allowLoginBySmsVerification === 'true') {
                        if(isEnablePremiumLoyaltyFeature && loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty && loginOrRegisterCtrl.unifiedAuthSettings && loginOrRegisterCtrl.unifiedAuthSettings.loginAndRegistrationWithOTP.shouldUnifyOTPFieldForEmailAndPhone) {
                            loginCtrl.currentView = LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL;
                            loginCtrl.previousViewState = [{view: LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL, otpPhoneOrEmail: ''}];
                        } else {
                            loginCtrl.currentView = LoginOrRegisterScreens.SELECT_OTP_OPTION;
                            loginCtrl.previousViewState = [{view: LoginOrRegisterScreens.SELECT_OTP_OPTION, otpPhoneOrEmail: ''}];
                        }
                    } else if (config.retailer.settings.allowLoginByEmailVerification === 'true') {
                        loginCtrl.otpOptionSelected = loginCtrl.LoginWithOtpOptions.EMAIL;
                        loginCtrl.currentView = LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL;
                        loginCtrl.previousViewState = [{view: LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL, otpPhoneOrEmail: ''}];
                    } else {
                        loginCtrl.otpOptionSelected = loginCtrl.LoginWithOtpOptions.PHONE_NUMBER;
                        loginCtrl.currentView = LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL;
                        loginCtrl.previousViewState = [{view: LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL, otpPhoneOrEmail: ''}];
                    }
                } else {
                    loginCtrl.currentView = LoginOrRegisterScreens.DEFAULT;
                    loginCtrl.previousViewState = [{view: LoginOrRegisterScreens.DEFAULT, otpPhoneOrEmail: ''}];
                }

                loginOrRegisterCtrl.currentView = loginCtrl.currentView
                loginOrRegisterCtrl.otpOptionSelected = loginCtrl.otpOptionSelected
            }

            function changeView(nextView, isGoBack) {
                if (isGoBack) {
                    var previousViewState = loginCtrl.previousViewState.pop();

                    if (previousViewState.view === loginCtrl.currentView) return SpDialogUrlManager.backClose();

                    if (previousViewState.view === 'enterOtpPhoneOrEmail' && !previousViewState.otpPhoneOrEmail && (loginCtrl.config.retailer.settings.allowLoginBySmsVerification !== 'true' || loginCtrl.config.retailer.settings.allowLoginByEmailVerification !== 'true')) {
                        return loginCtrl.backToLogin();
                    }
                    
                    loginCtrl.otpPhoneOrEmail = previousViewState.otpPhoneOrEmail;
                    loginCtrl.currentView = previousViewState.view;
                } else {
                    loginCtrl.previousViewState.push({view: loginCtrl.currentView, otpPhoneOrEmail: loginCtrl.otpPhoneOrEmail});
                    loginCtrl.currentView = nextView;
                }

                if(loginCtrl.currentView === 'selectOtpOption') {
                    if(loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty && loginOrRegisterCtrl.unifiedAuthSettings && loginOrRegisterCtrl.unifiedAuthSettings.loginAndRegistrationWithOTP.shouldUnifyOTPFieldForEmailAndPhone) {
                        loginCtrl.currentView = LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL;
                        loginCtrl.previousViewState = [{view: LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL, otpPhoneOrEmail: ''}];
                    }
                }

                loginOrRegisterCtrl.currentView = loginCtrl.currentView
            }

            //  This function is used to not allow user input text characters. <input type="number"/> is not used because it can have leading zeros.
            function filterNonDigits(event) {
                var key = event.key;
                var isDigit = /\d/.test(key);
                var isSpecialKey = [
                    'Backspace',
                    'Delete',
                    'Tab',
                    'Escape',
                    'Enter',
                    'Home',
                    'End',
                    'ArrowLeft',
                    'ArrowUp',
                    'ArrowRight',
                    'ArrowDown'
                ].includes(key);
                if (loginCtrl.otpOptionSelected === loginCtrl.LoginWithOtpOptions.PHONE_NUMBER && !isDigit && !isSpecialKey) {
                    event.preventDefault();
                }
            }

            function filterNonDigitsOnChange() {
                if (loginCtrl.otpOptionSelected === loginCtrl.LoginWithOtpOptions.PHONE_NUMBER && loginCtrl.otpPhoneOrEmail) {
                    loginCtrl.otpPhoneOrEmail = loginCtrl.otpPhoneOrEmail.replace(/\D/g, '');
                }
            }

            function fillOtpCodeInputsFromStringCode(stringOtpCode) {
                var otpCodeSplitted = stringOtpCode.split("");
                if (otpCodeSplitted.length === loginCtrl.numberOfDigitsForOTPCode.length) {
                  for (var i = 0; i < otpCodeSplitted.length; i++) {
                    loginCtrl.otpCodeInputs[i] = otpCodeSplitted[i];
                  }
                  var validateOtpCodeForm = document.querySelector('form[name="enterCodeToValidate"]');
                  loginCtrl.validateOtpCode(validateOtpCodeForm);
                } else {
                  console.error("Length mismatch in otp code from sms and otp code inputs:" + stringOtpCode);
                }
              }

            function handlePasteEvent(event) {
                event.preventDefault();
                event.target.blur()
                fillOtpCodeInputsFromStringCode(event.clipboardData.getData("text"));
              }

            function _validateResetCode() {
                if (Array.isArray(search.resetPasswordCode)) {
                    search.email = search.email[0];
                    search.resetPasswordCode = search.resetPasswordCode[search.resetPasswordCode.length - 1];
                }

                return user.validateResetCode(search).then(function (res) {
                    loginCtrl.isResetCodeError = !res.success;
                });
            }

            function login(form, event) {
                loginCtrl.showLoginErr = false;
                loginCtrl.showServerError = false;
                loginCtrl.loginErrFromServerMessage = '';
                loginCtrl.loginErrFromServerCode = '';
                loginCtrl.successMessage = '';

                if (form.$invalid) {
                    util.setFirstErrorInput(event.target || event.srcElement || event.path[0]);
                } else {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Login to site'}});

                    var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
                    loginCtrl.showCaptcha = false;
                    $rootScope.emailForReActivation = loginOrRegisterCtrl.user.email;

                    user.login(loginOrRegisterCtrl.user.email, loginOrRegisterCtrl.user.password, recaptchaHash)
                        .then(function() {
                            if(loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty) {
                                return user.getData().then(function () {
                                    if(user.data && user.data.isNeedJoinClub) {
                                        loginCtrl.completionFormFields = loginOrRegisterCtrl.additionalRegistrationFields
                                        loginOrRegisterCtrl.userTypeForCompletionForm = UserTypeForCompletionForm.SITE_USER_BUT_NOT_LOYALTY_MEMBER
                                        loginOrRegisterCtrl.checkboxes = loginOrRegisterCtrl.getCheckboxForCompletionForm();

                                        loginCtrl.completionFormFields = loginCtrl.completionFormFields
                                          .filter(function (field) {
                                            if (
                                              field.model === 'phoneNumber' &&
                                              loginCtrl.otpOptionSelected ===
                                                loginCtrl.LoginWithOtpOptions.PHONE_NUMBER &&
                                              loginCtrl.otpPhoneOrEmail
                                            )
                                              return false
                                            return true
                                          })
                                          .map(function (field) {
                                            if (field.model === 'phoneNumber') {
                                              field.minLength = 10
                                              field.maxLength = 10
                                            }
                                            return field
                                          })

                                        loginOrRegisterCtrl.user.policyApproval = Boolean(user.data.policyApprovalTime)
                                        loginOrRegisterCtrl.user.allowSendPromotions = user.data.allowSendPromotions

                                        if(user.data.phones && user.data.phones.length) {
                                            user.data.phones.forEach(function(phone) {
                                                if(phone.typeVal === PhoneTypes.VERIFY || phone.typeVal === PhoneTypes.MOBILE && phone.phoneNumber) {
                                                    loginOrRegisterCtrl.user.phoneNumber = phone.phoneNumber
                                                    loginOrRegisterCtrl.shouldDisablePhoneNumberField = true
                                                    return;
                                                }
                                            })
                                        }

                                        SpDialogUrlManager.backClose()

                                        return openFormCompletionDialog()
                                    }
                                    
                                    return userLoggedInSuccessfully()
                                });
                            } else return userLoggedInSuccessfully();
                        })
                        .catch(function (err) {
                            errorOccuredWhileUserLoggingIn(err);
                        });
                }
            }

            function userLoggedInSuccessfully() {
                if(!localStorage.getItem('isFirstUserLogin')){
                    $rootScope.$emit('loginForAnalyticServiceProvider');
                }
                localStorage.setItem('isFirstUserLogin', false);
                SpCaptcha.formSubmitSuccess(loginCtrl);

                toast.show({
                    timeout: 5000,
                    content: '{{\'Thank you, You are now logged in\'| translate}}'
                });

                //call hide in a function to prevent sending it unwanted params
                SpDialogUrlManager.backClose();

                user.getData().then(function() {
                    DataLayer.push(DataLayer.EVENTS.LOGIN);
                    if(!config.area && !config.branchAreaId && user.data && user.data.areaName && user.data.areaId) {
                        config.area = user.data.areaName;
                        config.branchAreaId = parseInt(user.data.areaId);
                    }
                }).then(function() {
                    if(!user.isVerified()) return UserVerificationDialog.show()
                });
            }

            function errorOccuredWhileUserLoggingIn(err) {
                SpCaptcha.formSubmitFailed(loginCtrl);
                //ToDo: discuss removing email - possible privacy issue
                DataLayer.push(DataLayer.EVENTS.ERROR_LOGIN, {data: {email: loginOrRegisterCtrl.user.email}});
                if (err && err.response && err.response.status === 'display_error') {
                    loginCtrl.loginErrFromServerMessage = err.response.error;
                    loginCtrl.loginErrFromServerCode = err.response.code;
                    loginCtrl.showServerError = true;
                    loginCtrl.showResendLink = true;
                } else if (err && err.response && err.response.status === 'captcha_required') {
                    loginCtrl.showCaptcha = true;
                } else if (config.retailer.status !== RETAILER_STATUSES.REGISTERED_ONLY) {
                    loginCtrl.showLoginErr = true;
                }
            }

            function loginWithFacebook() {
                user.loginWithFacebook().then(SpDialogUrlManager.backClose);
            }

            function showLoginWithOtp() {
                loginCtrl.otpPhoneOrEmail = '';
                var retailerSettings = loginCtrl.config.retailer.settings;
                var allowLoginByEmailVerification = JSON.parse(retailerSettings.allowLoginByEmailVerification);
                var allowLoginBySmsVerification = JSON.parse(retailerSettings.allowLoginBySmsVerification);
                if (allowLoginByEmailVerification && allowLoginBySmsVerification) {
                    loginCtrl.changeView(LoginOrRegisterScreens.SELECT_OTP_OPTION);
                } else if (allowLoginByEmailVerification) {
                    loginCtrl.otpOptionSelected = loginCtrl.LoginWithOtpOptions.EMAIL;
                    loginCtrl.changeView(LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL);
                } else if (allowLoginBySmsVerification) {
                    loginCtrl.otpOptionSelected = loginCtrl.LoginWithOtpOptions.PHONE_NUMBER;
                    loginCtrl.changeView(LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL);
                }

                loginOrRegisterCtrl.otpOptionSelected = loginCtrl.otpOptionSelected
            }

            function showEnterSmsOrMail(nextOtpScreenEnum) {
                loginCtrl.otpOptionSelected = nextOtpScreenEnum;
                loginOrRegisterCtrl.otpOptionSelected = loginCtrl.otpOptionSelected

                loginCtrl.changeView(LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL);
            }

            function sendOneTimeCode(form, event) {
                if(loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty) loginCtrl.otpOptionSelected = user.getTypeOfOTPFromCredentials(loginCtrl.otpPhoneOrEmail);

                if (form && form.$invalid && event) {
                    var formElement = angular.element(document.querySelector('form#enterOtpPhoneOrEmail'));
                    return util.setServerErrorToForm(form, formElement, {
                        response: {
                            errors: [{
                                param: loginCtrl.otpOptionSelected === loginCtrl.LoginWithOtpOptions.PHONE_NUMBER ? 'otpphone' : 'otpemail',
                                msg: 'required'
                            }]
                        }
                    });
                } else {
                    loginCtrl.otpPhoneOrEmail = loginCtrl.otpPhoneOrEmail.toString();
                    loginCtrl.otpCodeInputs = [];

                    var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
                    
                    user.generateOtpCode(loginCtrl.otpPhoneOrEmail, loginCtrl.otpOptionSelected, recaptchaHash)
                        .then(function () {
                            loginCtrl.showInvalidOrExpiredText = false;

                            loginCtrl.enterCodeToValidateFormTitle = _translate('Verify') + " " + _translate(loginCtrl.otpOptionSelected === loginCtrl.LoginWithOtpOptions.PHONE_NUMBER ? "Phone Number" : "Email Address");
                            loginCtrl.enterCodeToValidateFormSubtitle = _translate('Please enter the {number of digits} digit code sent to {otpCredential} through {typeOfOTP}')
                                .replace("{number of digits}", "<strong>" + config.retailer.settings.numberOfDigitsForOTP + "</strong>")
                                .replace("{otpCredential}", "<strong>" + _masked(loginCtrl.otpPhoneOrEmail) + "</strong>")
                                .replace("{typeOfOTP}", "<strong>" + (loginCtrl.otpOptionSelected === 1 ? "Email" : "SMS") + "</strong>");
                            loginCtrl.enterCodeToValidateFormButton = _translate("Continue");

                            loginCtrl.changeView(LoginOrRegisterScreens.ENTER_CODE_TO_VALIDATE);
                            loginCtrl.addEventListenersToOtpCodeInputsForBackspaceKey();
                        })
                        .catch(function (err) {
                            if (err && err.data && (err.data.error === 'User not found' || err.data.error === 'multipleUsersWithSamePhoneNumber')) {
                                loginCtrl.changeView(LoginOrRegisterScreens.OTP_ERROR);
                            } else if (err && err.data && err.data.error.includes('Account has been suspended')) {
                                loginCtrl.showAccountSuspended = true;
                                loginCtrl.changeView(LoginOrRegisterScreens.OTP_ERROR);
                            }
                        });
                }
            }

            function addEventListenersToOtpCodeInputsForBackspaceKey() {
                $timeout(function () {
                    var otpCodeInputElements = document.querySelectorAll('.otp-code-input .input');
                    otpCodeInputElements.forEach(function (inputElement, index) {
                        inputElement.addEventListener('keydown', function (event) {
                            var key = event.keyCode || event.charCode;
                            var isEmpty = inputElement.value === ''
                            if (key === 8 && isEmpty) {
                                if (index > 0) { // if not the first input field
                                    otpCodeInputElements[index - 1].focus(); // move focus to previous input field
                                }
                            }
                        });
                    });
                }, 1000);
            }

            function openFormCompletionDialog() {
                var dataToSend = {
                    type: loginOrRegisterCtrl.userTypeForCompletionForm,
                    phoneNumber: loginCtrl.otpOptionSelected === LoginWithOtpOptions.PHONE_NUMBER ? loginCtrl.otpPhoneOrEmail : loginOrRegisterCtrl.user.phoneNumber,
                    byLaws: loginOrRegisterCtrl.user.byLaws,
                    shouldDisableEmailField: loginOrRegisterCtrl.shouldDisableEmailField,
                    shouldDisablePhoneNumberField: loginOrRegisterCtrl.shouldDisablePhoneNumberField,
                    user: loginOrRegisterCtrl.user,
                    completionFormFields: loginCtrl.completionFormFields,
                    checkboxes: loginOrRegisterCtrl.checkboxes,
                    completionFormTitle: loginOrRegisterCtrl.completionFormTitle,
                    completionFormSubtitle: loginOrRegisterCtrl.completionFormSubtitle,
                    completionFormBtxText: loginOrRegisterCtrl.completionFormBtxText
                }
                return FormCompletionDialog.show(dataToSend)
              }

            function validateOtpCode(form, event) {
                if (form.$invalid) {
                    util.setFirstErrorInput(event.target || event.srcElement || event.path[0]);
                } else {
                    var otpCode = loginCtrl.verifiedPhoneCredential || loginCtrl.otpCodeInputs.join('');
                    loginCtrl.otpOptionSelected = loginCtrl.verifiedPhoneCredential ? LoginWithOtpOptions.VERIFIED_PHONE_CREDENTIAL : loginCtrl.otpOptionSelected;
                    loginOrRegisterCtrl.shouldDisableEmailField = false
                    loginOrRegisterCtrl.shouldDisablePhoneNumberField = false
                    loginCtrl.showInvalidOrExpiredText = false;
                    user.loginWithToken(undefined, otpCode, loginCtrl.otpPhoneOrEmail, loginCtrl.otpOptionSelected, null, loginCtrl.selectedUniqueEmail)
                        .then(function () {
                            if(loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty) {
                                return user.getData().then(function() {
                                    if(user.data && user.data.isNeedJoinClub) {
                                        loginCtrl.completionFormFields = loginOrRegisterCtrl.additionalRegistrationFields
                                        loginOrRegisterCtrl.userTypeForCompletionForm = UserTypeForCompletionForm.SITE_USER_BUT_NOT_LOYALTY_MEMBER
                                        loginOrRegisterCtrl.checkboxes = loginOrRegisterCtrl.getCheckboxForCompletionForm();

                                        if(loginCtrl.otpOptionSelected === LoginWithOtpOptions.PHONE_NUMBER) {
                                            loginCtrl.completionFormFields = loginCtrl.completionFormFields.filter(function (field) {
                                              if (field.model === 'phoneNumber') return false
                                              return true
                                            })
                                        }

                                        if(user.data.phones && user.data.phones.length) {
                                            user.data.phones.forEach(function(phone) {
                                                if(phone.typeVal === PhoneTypes.VERIFY || phone.typeVal === PhoneTypes.MOBILE && phone.phoneNumber) {
                                                    loginOrRegisterCtrl.user.phoneNumber = phone.phoneNumber
                                                    loginOrRegisterCtrl.shouldDisablePhoneNumberField = true
                                                    return;
                                                }
                                            })
                                        }

                                        loginOrRegisterCtrl.user.policyApproval = Boolean(user.data.policyApprovalTime)
                                        loginOrRegisterCtrl.user.allowSendPromotions = user.data.allowSendPromotions

                                        SpDialogUrlManager.backClose();

                                        return openFormCompletionDialog()

                                    }
                                    userLoggedInSuccessfully();
                                    loginCtrl.otpAttempts = 0;
                                })
                            }
                            userLoggedInSuccessfully();
                            loginCtrl.otpAttempts = 0;
                        }, function (err) {
                            if (loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty) {
                                if(err && err.data) {
                                    if (err.data.error === "User not found") {
                                        loginCtrl.completionFormFields = loginOrRegisterCtrl.allRegistrationFields
                                        loginOrRegisterCtrl.userTypeForCompletionForm = UserTypeForCompletionForm.NEW_USER
                                        loginOrRegisterCtrl.checkboxes = loginOrRegisterCtrl.getCheckboxForCompletionForm();

                                        loginOrRegisterCtrl.user = {}

                                        if(loginCtrl.otpOptionSelected === LoginWithOtpOptions.EMAIL) {
                                            loginOrRegisterCtrl.user.email = loginCtrl.otpPhoneOrEmail
                                            loginOrRegisterCtrl.shouldDisableEmailField = true
                                        }
                                        else if(loginCtrl.otpOptionSelected === LoginWithOtpOptions.PHONE_NUMBER) {
                                            loginOrRegisterCtrl.user.phoneNumber = loginCtrl.otpPhoneOrEmail
                                            loginOrRegisterCtrl.shouldDisablePhoneNumberField = true
                                        }

                                        SpDialogUrlManager.backClose();

                                        return openFormCompletionDialog()
                                    } 
                                    if(err.data.error === 'userOnlyExistedFromLoyalty') {
                                        loginCtrl.completionFormFields = loginOrRegisterCtrl.mandatoryFields
                                        loginOrRegisterCtrl.userTypeForCompletionForm = UserTypeForCompletionForm.LOYALTY_MEMBER_BUT_NOT_SITE_MEMBER
                                        loginOrRegisterCtrl.checkboxes = loginOrRegisterCtrl.getCheckboxForCompletionForm();

                                        if(err.data.errObj) {
                                            var loyaltyMemberData = err.data.errObj.loyaltyData
                                            if(loyaltyMemberData) {
                                                loginOrRegisterCtrl.user.firstName = loyaltyMemberData.firstName
                                                loginOrRegisterCtrl.user.lastName = loyaltyMemberData.lastName
                                                loginOrRegisterCtrl.user.phoneNumber = loyaltyMemberData.phoneNum
                                            }
                                        }

                                        loginCtrl.completionFormFields = loginCtrl.completionFormFields.filter(function(field) {
                                            if(field.model === 'firstName' && loginOrRegisterCtrl.user.firstName) return false
                                            if(field.model === 'lastName' && loginOrRegisterCtrl.user.lastName) return false
                                            if(field.model === 'phoneNumber' && loginOrRegisterCtrl.user.phoneNumber) return false
                                            return true
                                        })

                                        SpDialogUrlManager.backClose();

                                        return openFormCompletionDialog();
                                    } 
                                    if (err.data.error === 'multipleUsersWithSamePhoneNumber') {
                                        if(config.retailer.settings.isSingleEmailSelection === 'true') {
                                            loginCtrl.duplicatedEmails = err.data.errObj;
                                            loginCtrl.verifiedPhoneCredential = err.data.verifiedPhoneCredential;
                                            if(loginCtrl.duplicatedEmails && loginCtrl.duplicatedEmails.length) {
                                                loginCtrl.duplicatedEmails = loginCtrl.duplicatedEmails.map(function(email) {
                                                    return {
                                                        label: email,
                                                        value: email
                                                    }
                                                })
                                            }
                                            return loginCtrl.changeView(LoginOrRegisterScreens.CHOOSE_UNIQUE_EMAIL);
                                        }
                                        return loginCtrl.changeView(LoginOrRegisterScreens.OTP_ERROR);
                                    }
                                    if(err.data.message === 'cannotAuthenticateInTokenService') {
                                        loginCtrl.showInvalidOrExpiredText = true;
                                        loginCtrl.otpAttempts += 1;
                                        if (loginCtrl.otpAttempts === (+loginCtrl.config.retailer.settings.otpUserBlockAttempts || 3)) {
                                            loginCtrl.getValidLoginScreenBasedOnBackendConfiguration();
                                            loginCtrl.otpAttempts = 0;
                                        }
                                    }
                                }
							} else {
								if (err && err.data.message === "Account has been suspended") {
									loginCtrl.showAccountSuspended = true;
									loginCtrl.changeView(LoginOrRegisterScreens.OTP_ERROR);
								}
								loginCtrl.showInvalidOrExpiredText = true;
								loginCtrl.otpAttempts += 1;
								if (loginCtrl.otpAttempts === (+loginCtrl.config.retailer.settings.otpUserBlockAttempts || 3)) {
									loginCtrl.getValidLoginScreenBasedOnBackendConfiguration();
									loginCtrl.otpAttempts = 0;
								}
							}
                        });
                }
            }

            function goToEnterOtp() {
                loginCtrl.changeView(LoginOrRegisterScreens.ENTER_OTP_PHONE_OR_EMAIL);
                loginCtrl.otpPhoneOrEmail = '';
                loginCtrl.showAccountSuspended = false;
            }

            function setFocus(currentInputIndex) {
                if(loginCtrl.showInvalidOrExpiredText) loginCtrl.showInvalidOrExpiredText = false;
                if (currentInputIndex < (loginCtrl.numberOfDigitsForOTPCode.length - 1)) {
                    $timeout(function () {
                        if (loginCtrl.otpCodeInputs[currentInputIndex]) {
                            document.querySelectorAll('.otp-code-input .input')[currentInputIndex + 1].focus();
                        }
                    });
                }
            }

            function showForgotPassword() {
                loginCtrl.changeView(LoginOrRegisterScreens.FORGOTTEN_PASSWORD);
                setTimeout(function () {
                    var elementEmailAddress = document.getElementById('forgot_email');
                    if (elementEmailAddress) {
                        elementEmailAddress.focus();
                    }
                }, 1);
            }

            function getForgotPasswordCode(form) {
                if (form.$invalid) {
                    loginCtrl.captchaIsInvalid = true;
                    loginCtrl.captchaMessage = _translate('Verification expired. Check the checkbox again')
                    return;
                }

                loginCtrl.showResetCaptcha = false;
                var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();
                user.forgotPassword(loginOrRegisterCtrl.user.email, recaptchaHash).finally(function(){
                    loginCtrl.showForgotPasswordSentAlert = true
                });
            }

            function savePassword(form, event) {
                var formElement = event.target || event.srcElement || event.path[0];
                if (form.$invalid) {
                    loginCtrl.captchaIsInvalid = true;
                    loginCtrl.captchaMessage = _translate('Verification expired. Check the checkbox again')
                    return;
                } else if (loginCtrl.resetData.newPassword != loginCtrl.resetData.confirmPassword) {
                    return util.setServerErrorToForm(form, formElement, {
                        response: {
                            errors: [{
                                param: 'resetConfirmPassword',
                                msg: 'Passwords do not match'
                            }]
                        }
                    });
                }

                var recaptchaHash = SpCaptcha.getLastCaptchaVerifyHash();

                // If captcha required just login without trying to save password again because code is already used
                var forgotPasswordChange = loginCtrl.showSaveCaptcha ?
                    Promise.resolve() : 
                    user.forgotPasswordChange(loginCtrl.resetData.email, loginCtrl.resetData.code, loginCtrl.resetData.newPassword);

                loginCtrl.showSaveCaptcha = false;
                forgotPasswordChange.then(function () {
                    return user.login(loginCtrl.resetData.email, loginCtrl.resetData.newPassword, recaptchaHash);
                }).then(SpDialogUrlManager.backClose).catch(function (err) {
                    err.response = err.response || {};
                    SpCaptcha.formSubmitFailed(loginCtrl);
                    if(err.response.code === 'loginAccountNotActivated') {
                        loginCtrl.changeView(LoginOrRegisterScreens.DEFAULT);
                        loginCtrl.loginErrFromServerMessage = err.response.error;
                        loginCtrl.showServerError = true;
                        loginCtrl.showResendLink = true;
                    } else if (err && err.response && err.response.status === 'captcha_required') {
                        loginCtrl.showSaveCaptcha = true;
                    }
                    // else {
                        // util.setServerErrorToForm(form, formElement, err);
                    // }
                });
            }

            function resendActivationLink() {
                loginCtrl.showResendLink = false;

                user.resendActivationLink(loginOrRegisterCtrl.user.email).then(function () {
                    loginCtrl.successMessage = 'A verification email will be sent to your email box. The link is valid for {link_ttl} minutes';
                    loginCtrl.showServerError = false;
                }).catch(function(err) {
                    loginCtrl.loginErrFromServerMessage = (err.response || {}).error;
                    loginCtrl.showServerError = true;
                });
            }

            function backToLogin() {
                loginCtrl.changeView(LoginOrRegisterScreens.DEFAULT);
                loginCtrl.isResetCodeError = false;
                loginCtrl.showAccountSuspended = false;
                delete search.resetCode;
                delete search.email;
            }

            function backClose() {
                return SpDialogUrlManager.backClose();
            }

            $scope.$watch("loginCtrl.currentView", function () {
				if (loginOrRegisterCtrl.shouldAllowLoginOrRegistrationToBothSiteAndLoyalty) {
					var viewsDontHaveFooter = [
						"enterCodeToValidate",
						"chooseUniqueEmail",
						"enterCompletionForm",
						"forgottenPassword",
						"resetPassword",
						"resetPasswordError",
						"enterFacebookRegistrationForm",
					];
					if (loginCtrl.currentView && viewsDontHaveFooter.includes(loginCtrl.currentView))
						loginOrRegisterCtrl.showFooter = false;
					else loginOrRegisterCtrl.showFooter = true;

                    if(loginOrRegisterCtrl.unifiedAuthSettings) {
                        if (
                           !loginOrRegisterCtrl.unifiedAuthSettings.otpForm.shouldAllowCustomersUseEmailAndPassword &&
                           !loginOrRegisterCtrl.unifiedAuthSettings.facebookRegistrationForm.shouldEnableFacebookRegistrationForm
                       ) {
                           loginOrRegisterCtrl.showFooter = false;
                       }
                    }
				}
			});

        }]);

    app.filter('masked', function() {
        return function(string) {
            var firstThreeCharacters = string.slice(0, 3);
            var lastTwoCharacters = string.substr(string.length - 2, 2);
            var starts = '*'.repeat(string.length - 5);
            return firstThreeCharacters + starts + lastTwoCharacters;
        };
    });
})(angular, app);
