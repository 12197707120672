(function (angular, app) {
    'use strict';

    app.directive('spListItems', ['Util', function () {
        return {
            templateUrl: 'template/directives/sp-list-items/index.html',
            replace: true,
            scope: {
                showIrrelevantSales: '=?',
                showIrrelevantSellDate: '=?',
                radioValue: '=?',
                showDeliveryProducts: '=?',
                splitView: '=?',
                deliveryDate: '=?'
            },
            controller: ['$scope', '$q', '$timeout', 'Cart', 'SP_SERVICES', 'Dialog', 'Util', '$rootScope', '$filter', 'Config', function ($scope, $q, $timeout, cart, SP_SERVICES, dialog, util, $rootScope, $filter, config) {
                $scope.radioValue = $scope.radioValue || 'category';
                $scope.deliveryFeeLine = null;
                $scope.openDeliveryFeePopup = openDeliveryFeePopup;
                _setItems();
                
                $scope.removeItem = function (item) {
                    cart.removeLine(item, true);
                    _setItems();
                };

                $scope.hoverMode = function ($event) {
                    angular.element(document.querySelector('tr.item-row.hover-mode')).removeClass('hover-mode');
                    angular.element($event.currentTarget.parentElement.parentElement).addClass('hover-mode');
                };

                function openDeliveryFeePopup() {
                    dialog.show({
                        controller: 'DeliveryFeeInfoCtrl',
                        templateUrl: 'template/dialogs/delivery-fee-info/index.html',
                        styleClass: 'delivery-fee-info-dialog',
                        bypass: true,
                        locals: {
                            displayText: $rootScope.config.retailer.settings.legalTermsDeliveryText || $filter('translate')('delivery-fee-explain-default'),
                        }
                    });
                }

                function _setItems() {
                    $scope.items = [];
                    $scope.coupons = [];
                    $scope.itemsByCategories = [];
                    $scope.itemsByOrder = [{id: 0, items: {}}];
                    $scope.hasSpecialReminders = !!Object.keys(cart.specialReminders.inCart || {}).length;

                    var itemsByCategoriesObj = {},
                        notActiveItems = [];
                    angular.forEach(cart.lines, function (line) {
                        if (line.type == SP_SERVICES.CART_LINE_TYPES.DELIVERY && line.id !== '-10') {
                            $scope.deliveryFeeLine = line;
                            return;
                        }

                        if (line.type == SP_SERVICES.CART_LINE_TYPES.DELIVERY && !$scope.showDeliveryProducts) {
                            return;
                        }

                        //== coupon that not displayed in cart
                        if (line.isHideFromCart) {
                            return;
                        }

                        if ($scope.showIrrelevantSales) {
                            if (!util.isIrrelevantSale(line)) {
                                return;
                            }
                            if(line.gifts){
                                line.gifts = line.gifts.filter(function(gift) {
                                    return gift.promotion.endDate < $scope.deliveryDate;
                                });
                            }
                        }
                        if($scope.showIrrelevantSellDate){
                             if (util.isSellOnDate(line, new Date($scope.deliveryDate))) {
                                return;
                            }
                        }

                        if (line.type == SP_SERVICES.CART_LINE_TYPES.COUPON) {
                            return $scope.coupons.push(line);
                        }

                        //remove inactive or not connected lines of type product
                        if ((!line.type || line.type == SP_SERVICES.CART_LINE_TYPES.PRODUCT) && (!line.isPseudo && (!line.product.family || !line.product.family.categoriesPaths || !line.product.family.categoriesPaths.length))) {
                            return notActiveItems.push(line);
                        }

                        $scope.itemsByOrder[0].items[line.id] = line;

                        var category = (line.product.family && line.product.family.categoriesPaths && line.product.family.categoriesPaths[0][0]) ||
                            (line.product.categories && line.product.categories[0]) || {id: 0};
                        if (!itemsByCategoriesObj[category.id]) {
                            itemsByCategoriesObj[category.id] = {id: category.id || 0, items: [], names: category.names};
                            if (!category.names) {
                                itemsByCategoriesObj[category.id].name = category.name || 'Unknown';
                            }
                            $scope.itemsByCategories.push(itemsByCategoriesObj[category.id]);
                        }

                        itemsByCategoriesObj[category.id].items.push(line);
                        $scope.items.push(line);
                    });

                    if (!notActiveItems.length) {
                        return $q.resolve();
                    }

                    return dialog.show({
                        controller: ['$scope', function ($scope) {
                            $scope.items = notActiveItems;
                            $scope.hide = dialog.hide;
                        }],
                        template: '<h5>{{"We are sorry, but the following products are out of stock" | translate}}:</h5>' +
                        '<div ng-repeat="item in items">' +
                        '<span>{{item.product | productName}}</span>{{(!$last ? ", " : "")}}' +
                        '</div><br/><br/>' +
                        '<div class="buttons right">' +
                        '<button ng-click="hide();">{{"Remove from cart & continue" | translate}}</button>' +
                        '</div>',
                        disableClosing: true
                    }).then(function () {
                        angular.forEach(notActiveItems, function (notActiveItem) {
                            cart.removeLine(notActiveItem, true);
                        });
                    });
                }

                util.currentScopeListener($scope, $scope.$root.$on('cart.update.complete', _setItems));
                $rootScope.$on('store.changed', function () {
                    cart.init();
                    cart.forceUpdate().then(function () {
                        var selectedStore = {};
                        try {
                            selectedStore = $rootScope.config.getBranchArea() || {}
                        } catch (e) {
                            // do nothing
                        }
                        angular.forEach(cart.lines, function (line) {
                            if (line.product && selectedStore.deliveryProduct_Id == line.product.id &&
                                line.type == SP_SERVICES.CART_LINE_TYPES.DELIVERY && line.id !== '-10') {
                                $scope.deliveryFeeLine = line;
                                config.deliveryAreaPrice = line.product && line.product.branch && line.product.branch.regularPrice;
                            }
                        })
                    });
                });

                /**
                 * 
                 * @param {'mouseleave' | 'mouseenter'} eventType 
                 * @param {string | number} lineId 
                 */
                $scope.toggleProductActions = function(eventType, lineId) {
                    var elementId = 'item_line_' + lineId;
                    var host = document.getElementById(elementId);
                    if (host) {
                        if (eventType === 'mouseleave') {
                            host.classList.add('hide-product-actions');
                        }
                        if (eventType === 'mouseenter') {
                            host.classList.remove('hide-product-actions');
                        }
                    }
                }
            }]
        };
    }]);

    //TODO move to templates once we move the scripts to their respective locations
    app.controller('ElasticHeaderCtrl', ['$scope', '$rootScope', '$timeout', 'Util', 'Cart', 'Config', 'SP_SERVICES',
        function ($scope, $rootScope, $timeout, Util, Cart, Config, SP_SERVICES) {
        var watcher = $scope.$watch(function() {
            return document.getElementsByClassName('static-row-content')[0];
        }, function(newVal) {
            if (newVal) {
                watcher();
                angular.forEach([100, 300, 500, 1000], function(time) {
                    $timeout(function() {
                        _changeHeaderWidth();
                    }, time);
                });
            }
        });

        $rootScope.$on('store.changed', function () {
            Cart.forceUpdate().then(function () {
                var selectedStore = {};
                try {
                    selectedStore = $rootScope.config.getBranchArea() || {}
                } catch (e) {
                    // do nothing
                }
                Config.deliveryAreaPrice = null;
                angular.forEach(Cart.lines, function (line) {
                    if (line.product && selectedStore.deliveryProduct_Id == line.product.id &&
                        line.type == SP_SERVICES.CART_LINE_TYPES.DELIVERY && line.id !== '-10') {
                        Config.deliveryAreaPrice = line.product && line.product.branch && line.product.branch.regularPrice;
                    }
                })


            });
        });

        function _changeHeaderWidth () {
            var staticRow = document.getElementsByClassName('static-row')[0],
                elasticRow = document.getElementsByClassName('elastic-row')[0],
                staticTdWidthsForExpanshion = [],
                elasticTdsToExpand = [];

            if (!staticRow) {
                return;
            }

            angular.forEach(staticRow.childNodes, function(tdElement) {
                if (tdElement.nodeName === 'TH') {
                    staticTdWidthsForExpanshion.push(tdElement.clientWidth);
                }
            });

            angular.forEach(elasticRow.childNodes, function(tdElement) {
                if (tdElement.nodeName === 'SPAN') {
                    elasticTdsToExpand.push(tdElement);
                }
            });

            angular.forEach(elasticTdsToExpand, function(tdToExpand, index) {
                tdToExpand.style.width = staticTdWidthsForExpanshion[index] + 'px';
            });

            elasticRow.style.visibility = 'visible';
        }

        Util.currentScopeListener($scope, $rootScope.$on('config.language.change', function () {
            $timeout(function() {
                _changeHeaderWidth();
            });
        }));

        Util.currentScopeListener($scope, $rootScope.$on('resize', function () {
            $timeout(function() {
                _changeHeaderWidth();
            });
        }));
    }]);
})(angular, app);
