(function (angular, app) {
    'use strict';

    app
        .directive('spDropdown', ['$parse', '$rootScope', '$timeout', 'Util', function ($parse, $rootScope, $timeout, util) {
            return {
                restrict: 'E',
                transclude: true,
                scope: {},
                bindToController: {
                    spModel: '=?',
                    isMulti: '<?multiValue',
                    onOpen: '&?',
                    onClose: '&?',
                    spChange: '&?'
                },
                controllerAs: 'spDropdownCtrl',
                template: function (element, attrs) {
                    return '' +
                        '<ul class="sp-dropdown-options-wrapper" role="listbox"></ul>';
                },

                controller: ['$scope', '$element', '$attrs', '$transclude', '$compile', function ($scope, element, attrs, $transclude, $compile) {
                    var spDropdownCtrl = this;
                    spDropdownCtrl.toggleDropdown = toggleDropdown;
                    spDropdownCtrl.setSpDropdownValue = setSpDropdownValue;

                    util.currentScopeListener($scope, $rootScope.$on('document.click', function () {
                        if (spDropdownCtrl.isOpen) {
                            spDropdownCtrl.toggleDropdown();
                        }
                    }));

                    element[0].setAttribute('role', 'application')

                    $transclude(function(elements) {
                        var stTextElement,
                            optionElements = [];// = angular.element();
                        angular.forEach(elements, function(transElement) {
                            transElement = angular.element(transElement);
                            if (transElement.hasClass('sp-dropdown-text')) {
                                stTextElement = transElement;
                            } else {
                                optionElements.push(transElement[0]);
                            }
                        });

                        if (!stTextElement) {
                            stTextElement = angular.element('<sp-text>{{spDropdownCtrl.spModel}}</sp-text>');
                            $compile(stTextElement)($scope);
                        }

                        var optionsWrapperElement = angular.element(element[0].querySelector('.sp-dropdown-options-wrapper'));
                        angular.forEach(optionElements, function (element) {
							optionsWrapperElement.append(element);
                        });
                        element.prepend(stTextElement[0]);
                    });

                    function toggleDropdown(event) {
                        if(spDropdownCtrl.isOpen && spDropdownCtrl.spModel && spDropdownCtrl.spModel.direction && ['ltr', 'rtl'].includes(spDropdownCtrl.spModel.direction)) {
                            $rootScope.$emit('changeLanguage');
                        }
                        if (spDropdownCtrl.isOpen && spDropdownCtrl.onClose && spDropdownCtrl.onClose() === false) {
                            return;
                        } else if (!spDropdownCtrl.isOpen && spDropdownCtrl.onOpen && spDropdownCtrl.onOpen() === false) {
                            return;
                        }

                        spDropdownCtrl.isOpen = !spDropdownCtrl.isOpen;

                        if (spDropdownCtrl.isOpen) {
                            angular.element(document.querySelectorAll('sp-dropdown.open')).removeClass('open');
                            element.addClass('open');
                        } else {
                            element.removeClass('open');
                        }
                        element.removeClass('on-top');

                        if (spDropdownCtrl.isOpen) {
                            $timeout(function () {
                                var offsetElement = element[0],
                                    top = offsetElement.querySelector('.sp-dropdown-options-wrapper').offsetHeight + offsetElement.offsetTop,
                                    isFixed = util.getCurrentStyleProp(offsetElement, 'position') === 'fixed';
                                while (offsetElement.offsetParent) {
                                    offsetElement = offsetElement.offsetParent;
                                    top += offsetElement.offsetTop;
                                    isFixed = util.getCurrentStyleProp(offsetElement, 'position') === 'fixed' || isFixed;
                                }
                                if (!isFixed) {
                                    top -= $rootScope.scrollTop;
                                }
                                if (top > window.innerHeight) {
                                    element.addClass('on-top');
                                }

                                element[0].querySelector('ul .option-link').focus();
                            }, 10);
                        } else {
                            if(!$rootScope.isShowAutocomplete) {
                                element.children()[0].focus();
                            }
                        }

                        event && event.stopPropagation();
                        event && event.stopImmediatePropagation();
                    }

                    function setSpDropdownValue(value, elem) {
                        var currentValue = spDropdownCtrl.spModel;
                        if (spDropdownCtrl.isMulti) {
                            currentValue = angular.isArray(currentValue) ? currentValue : currentValue ? [currentValue] : [];
                            var existIndex = -1;
                            angular.forEach(currentValue, function (item, index) {
                                if (item == value) {
                                    existIndex = index;
                                }
                            });
                            var inputElement = elem[0].querySelector('label > .option-checkbox > input'),
                                isSelected = inputElement ? inputElement.checked : false;
                            if (!isSelected && existIndex > -1) {
                                currentValue.splice(existIndex, 1);
                            } else if (isSelected && existIndex == -1) {
                                currentValue.push(value);
                            }
                        } else {
                            currentValue = value;
                        }
                        spDropdownCtrl.spModel = currentValue;

                        $scope.$applyAsync();
                    }

                    $scope.$watch('spDropdownCtrl.spModel', function(newVal, oldVal) {
                        if (spDropdownCtrl.spChange && oldVal !== newVal) {
                            spDropdownCtrl.spChange({newVal: newVal, oldVal: oldVal});
                        }
                    });
                }]
            };
        }])
        .directive('spText', function() {
            return {
                transclude: true,
                restrict: 'E',
                replace: true,
                require: '^^spDropdown',
                scope: {},
                template: '' +
                    '<div class="sp-dropdown-text" ng-click="spDropdownCtrl.toggleDropdown($event)" tabindex="0" aria-haspopup="true" aria-expanded="{{spDropdownCtrl.isOpen || false}}">' +
                    '   <span class="for-vertical-align"></span>' +
                    '   <span ng-transclude></span>' +
                    '   <div class="arrow" tabindex="-1"></div>' +
                    '</div>',
                link: function(scope, element, attrs, dropDownCtrl) {
                    scope.spDropdownCtrl = dropDownCtrl;
                },
            };
        })
        .directive('spOption', function () {
            return {
                restrict: 'E',
                scope: {},
                bindToController: {
                    href: '<spHref',
                    sref: '@spSref',
                    spTarget: '<',
                    spValue: '<',
                    multiCheckboxModel: '<',
                    spClick: '&?',
                    spSelected: '<?'
                },
                require: '^^spDropdown',
                replace: true,
                transclude: true,
                controllerAs: 'spOptionCtrl',
                template: function (element, attrs) {
                    return '' +
                        '<li class="sp-option" ng-class="{\'active\': spOptionCtrl.active}" role="option">' +
                        '<label>' +
                        // '<span class="option-checkbox" ng-if="isMulti"><input type="checkbox"' + (attrs.multiCheckboxModel ? ' ng-model="' + attrs.multiCheckboxModel + '"' : '') + '/></span>' +
                        '<span class="option-content">' +
                        '<a tabindex="-1" ng-transclude ng-if="spOptionCtrl.href || spOptionCtrl.sref" ng-focus="spOptionCtrl.onFocus()" ng-blur="spOptionCtrl.onBlur()" ' + (attrs.spHref ? 'ng-href="{{spOptionCtrl.href}}" ' : '') + (attrs.spSref ? 'ui-sref="{{spOptionCtrl.sref}}" ' : '') +
                        ' class="option-link"' + (attrs.spTarget ? ' target="' + attrs.spTarget + '"' : '') + '></a>' +
                        '<span tabindex="-1" class="option-link no-design" aria-label="{{spOptionCtrl.spValue.label | translate}}" ng-click="spOptionCtrl.click()" ng-transclude ng-if="!spOptionCtrl.href && !spOptionCtrl.sref" ng-focus="spOptionCtrl.onFocus()" ng-blur="spOptionCtrl.onBlur()"></span>' +
                        '</span>' +
                        '</label>' +
                        '</li>';
                },
                link: function(scope, element, attrs, dropDownCtrl) {
                    scope.spDropdownCtrl = dropDownCtrl;
                },
                controller: ['$scope', '$element', '$attrs', '$location', '$state', '$timeout', function($scope, element, $attrs, $location, $state, $timeout) {
                    var spOptionCtrl = this,
                        optionButton = angular.element(element[0].querySelector('.option-link'));

                    spOptionCtrl.click = click;
                    spOptionCtrl.onFocus = onFocus;
                    spOptionCtrl.onBlur = onBlur;

                    element.bind('keydown', _optionKeyDown);

                    optionButton && optionButton.bind('focus', _optionFocus);
                    optionButton && optionButton.bind('blur', _optionBlur);

                    function onFocus() {
                        spOptionCtrl.active = true;
                    }

                    function onBlur() {
                        spOptionCtrl.active = false;
                    }

                    function click() {
                        if ($scope.spDropdownCtrl.isMulti) {
                            event.stopPropagation();
                            event.stopImmediatePropagation();
                        }

                        if (spOptionCtrl.spClick) {
                            spOptionCtrl.spClick();
                        }

                        if ($attrs.spValue) {
                            $scope.spDropdownCtrl.setSpDropdownValue(spOptionCtrl.spValue, element);
                        }
                    }

                    function _optionFocus() {
                        spOptionCtrl.inFocus = true;
                    }

                    function _optionBlur() {
                        spOptionCtrl.inFocus = false;
                    }

                    function _optionKeyDown(event) {
                        var _currentTarget = event.currentTarget || event.target || event.srcElement;
                        var _parentElement = _currentTarget.parentElement || _currentTarget.parentNode;
                        switch (event.which) {
                            case 9:
                                $scope.spDropdownCtrl.toggleDropdown();
                                break;
                            case 27:
                                $scope.spDropdownCtrl.toggleDropdown();
                                event.preventDefault();
                                event.stopImmediatePropagation();
                                break;
                            case 13:
                                event.preventDefault();
                                event.stopImmediatePropagation();
                                if (spOptionCtrl.href) {
                                    $location.path(spOptionCtrl.href);
                                }
                                if (spOptionCtrl.sref) {
                                    $state.go(spOptionCtrl.sref);
                                }
                                $scope.spDropdownCtrl.toggleDropdown();
                                break;
                            case 40:
                                (_currentTarget.nextElementSibling || _parentElement.firstElementChild).querySelector('.option-link').focus();
                                event.preventDefault();
                                event.stopImmediatePropagation();
                                break;
                            case 38:
                                (_currentTarget.previousElementSibling || _parentElement.lastElementChild).querySelector('.option-link').focus();
                                event.preventDefault();
                                event.stopImmediatePropagation();
                                break;
                        }
                    }

                    $scope.$watch('spDropdownCtrl.isOpen', function(isOpen) {
                        if (isOpen && spOptionCtrl.spSelected) {
                            $timeout(function () {
                                element[0].querySelector('.option-link').focus();
                            }, 20);
                        }
                    });
                }]
            };
        });

    //todo: this directive should be only in the kikar template
    app
        .directive('spKikarDropdown', ['$parse', '$rootScope', '$timeout', 'Util', function ($parse, $rootScope, $timeout, util) {
            return {
                restrict: 'E',
                transclude: true,
                scope: {},
                bindToController: {
                    spModel: '=?',
                    isMulti: '<?multiValue',
                    onOpen: '&?',
                    onClose: '&?',
                    spChange: '&?',
                    spInvalid: '=?'
                },
                controllerAs: 'spKikarDropdownCtrl',
                template: function (element, attrs) {
                    return '' +
                        '<ul class="sp-dropdown-options-wrapper" role="listbox"></ul>';
                },
                controller: ['$scope', '$element', '$attrs', '$transclude', '$compile', function ($scope, element, attrs, $transclude, $compile) {
                    var spKikarDropdownCtrl = this;
                    spKikarDropdownCtrl.toggleDropdown = toggleDropdown;
                    spKikarDropdownCtrl.setSpDropdownValue = setSpDropdownValue;

                    element[0].setAttribute('role', 'application')

                    util.currentScopeListener($scope, $rootScope.$on('document.click', function () {
                        if (spKikarDropdownCtrl.isOpen) {
                            spKikarDropdownCtrl.toggleDropdown();
                        }
                    }));

                    $transclude(function(elements) {
                        var stTextElement,
                            optionElements = [];// = angular.element();
                        angular.forEach(elements, function(transElement) {
                            transElement = angular.element(transElement);
                            if (transElement.hasClass('sp-dropdown-text')) {
                                stTextElement = transElement;
                            } else {
                                optionElements.push(transElement[0]);
                            }
                        });

                        if (!stTextElement) {
                            stTextElement = angular.element('<sp-kikar-text>{{spKikarDropdownCtrl.spModel}}</sp-kikar-text>');
                            $compile(stTextElement)($scope);
                        }

                        var optionsWrapperElement = angular.element(element[0].querySelector('.sp-dropdown-options-wrapper'));
                        angular.forEach(optionElements, function(element) {
                            optionsWrapperElement.append(element);
                        });
                        element.prepend(stTextElement[0]);
                    });

                    function toggleDropdown(event) {
                        if(spKikarDropdownCtrl.isOpen && spKikarDropdownCtrl.spModel && spKikarDropdownCtrl.spModel.direction && ['ltr', 'rtl'].includes(spKikarDropdownCtrl.spModel.direction)) {
                            $rootScope.$emit('changeLanguage');
                        }
                        if (spKikarDropdownCtrl.isOpen && spKikarDropdownCtrl.onClose && spKikarDropdownCtrl.onClose() === false) {
                            return;
                        } else if (!spKikarDropdownCtrl.isOpen && spKikarDropdownCtrl.onOpen && spKikarDropdownCtrl.onOpen() === false) {
                            return;
                        }

                        event && event.stopImmediatePropagation();

                        spKikarDropdownCtrl.isOpen = !spKikarDropdownCtrl.isOpen;

                        if (!spKikarDropdownCtrl.isOpen) {
                            if(!$rootScope.isShowAutocomplete) {
                                element[0].querySelector('.sp-dropdown-text').focus();
                            }

                            return element.removeClass('open on-top');
                        }

                        angular.element(document.querySelectorAll('sp-kikar-dropdown.open')).removeClass('open');

                        element.addClass('open');

                        $timeout(function () {
                            var offsetElement = element[0],
                                top = offsetElement.querySelector('.sp-dropdown-options-wrapper').offsetHeight + offsetElement.offsetTop,
                                isFixed = util.getCurrentStyleProp(offsetElement, 'position') == 'fixed';
                            while (offsetElement.offsetParent) {
                                offsetElement = offsetElement.offsetParent;
                                top += offsetElement.offsetTop;
                                isFixed = util.getCurrentStyleProp(offsetElement, 'position') == 'fixed' || isFixed;
                            }
                            if (!isFixed) {
                                top -= $rootScope.scrollTop;
                            }
                            if (top > window.innerHeight) {
                                element.addClass('on-top');
                            }

                            element[0].querySelector('ul .label').focus();
                        }, 10);
                    }

                    function setSpDropdownValue(value, elem) {
                        var currentValue = spKikarDropdownCtrl.spModel;
                        if (spKikarDropdownCtrl.isMulti) {
                            var existIndex = -1;
                            for (var i = 0; i < currentValue.length; i++) {
                                if (currentValue[i] === value) {
                                    existIndex = i;
                                    break;
                                }
                            }

                            if (elem[0].querySelector('label > .option-checkbox > input').checked) {
                                elem.addClass('selected');
                                if (existIndex === -1) {
                                    currentValue.push(value);
                                }
                            } else {
                                elem.removeClass('selected');
                                if (existIndex > -1) {
                                    currentValue.splice(existIndex, 1);
                                }
                            }
                        } else {
                            currentValue = value;
                        }

                        spKikarDropdownCtrl.spModel = currentValue;

                        $scope.$applyAsync();
                    }

                    $scope.$watch('spKikarDropdownCtrl.spModel', function(newVal, oldVal) {
                        if (spKikarDropdownCtrl.spChange && oldVal !== newVal) {
                            spKikarDropdownCtrl.spChange({newVal: newVal, oldVal: oldVal});
                        }
                    });
                }]
            };
        }])
        .directive('spKikarText', function() {
            return {
                transclude: true,
                restrict: 'E',
                replace: true,
                require: '^^spKikarDropdown',
                scope: {},
                template: '' +
                '<div class="sp-dropdown-text" ng-class="{\'sp-invalid\': spKikarDropdown.spInvalid}" ng-click="spKikarDropdown.toggleDropdown($event)" tabindex="0" aria-haspopup="true" aria-expanded="{{spKikarDropdown.isOpen}}">' +
                '   <span class="for-vertical-align"></span><!--' +
                '   --><span ng-transclude></span><!--' +
                '   --><svg focusable="false" class="arrow"><use xlink:href="#sp-icons-arrow-down"></use></svg>' +
                '</div>',
                link: function(scope, element, attrs, dropDownCtrl) {
                    scope.spKikarDropdown = dropDownCtrl;
                }
            };
        })
        .directive('spKikarOption', ['$parse', '$timeout', '$location', '$state', function ($parse, $timeout, $location, $state) {
            return {
                restrict: 'E',
                scope: {},
                bindToController: {
                    href: '<spHref',
                    sref: '@spSref',
                    spTarget: '<',
                    spValue: '<',
                    multiCheckboxModel: '<',
                    spClick: '&?',
                    spSelected: '<?',
                    isNotClickable: '<?'
                },
                require: '^^spKikarDropdown',
                replace: true,
                transclude: true,
                controllerAs: 'spKikarOption',
                template: function (element, attrs) {
                    return '' +
                        '<li class="sp-kikar-option" role="option" ng-class="{active: spKikarOption.active}" xmlns="http://www.w3.org/1999/html">' +
                            '<button tabindex="-1" type="button" class="no-design label" ng-if="!spKikarOption.href && !spKikarOption.sref && !spKikarOption.isNotClickable" ng-click="spKikarOption.click($event)" ng-focus="spKikarOption.onFocus()" ng-blur="spKikarOption.onBlur()">' +
                                '<span class="for-vertical-align"></span>' +
                                '<span class="option-checkbox" ng-if="spDropdownCtrl.isMulti"><input type="checkbox"/></span>' +
                                '<span class="option-content" aria-label="{{spKikarOption.spValue.label}}" ng-transclude></span>' +
                            '</button>' +
                            '<a tabindex="-1" class="option-link label" ng-if="spKikarOption.href || spKikarOption.sref" ng-focus="spKikarOption.onFocus()" ng-blur="spKikarOption.onBlur()" ' + (attrs.spHref ? 'ng-href="{{spKikarOption.href}}" ' : '') + (attrs.spSref ? 'ui-sref="{{spKikarOption.sref}}" ' : '') + (attrs.spTarget ? ' target="{{spKikarOption.spTarget}}"' : '') + '>' +
                                '<span class="for-vertical-align"></span>' +
                                '<span class="option-checkbox" ng-if="spDropdownCtrl.isMulti"><input type="checkbox"/></span>' +
                                '<span class="option-content" ng-transclude></span>' +
                            '</a>' +
                            '<div ng-if="spKikarOption.isNotClickable" tabindex="-1" class="option-link label" ng-focus="spKikarOption.onFocus()" ng-blur="spKikarOption.onBlur()">' +
                                '<span class="for-vertical-align"></span>' +
                                '<span class="option-checkbox" ng-if="spDropdownCtrl.isMulti"><input type="checkbox"/></span>' +
                                '<span class="option-content" ng-transclude></span>' +
                            '</div>'
                        '</li>';
                },
                link: function(scope, element, attrs, dropDownCtrl) {
                    scope.spKikarDropdown = dropDownCtrl;
                },
                controller: ['$scope', '$element', '$attrs', function($scope, element, $attrs) {
                    var spKikarOption = this;

                    spKikarOption.click = click;
                    spKikarOption.onFocus = onFocus;
                    spKikarOption.onBlur = onBlur;

                    element.bind('keydown', _optionKeyDown);

                    function click(event) {
                        if (spKikarOption.spClick) {
                            spKikarOption.spClick();
                        }

                        if ($attrs.spValue) {
                            $timeout(function () {
                                $scope.spKikarDropdown.setSpDropdownValue(spKikarOption.spValue, element);
                                event.stopImmediatePropagation();
                            }, 10);
                        }
                    }

                    function onFocus() {
                        spKikarOption.active = true;
                    }

                    function onBlur() {
                        spKikarOption.active = false;
                    }

                    function _optionKeyDown(event) {
                        var _currentTarget = event.currentTarget || event.target || event.srcElement;
                        var _parentElement = _currentTarget.parentElement || _currentTarget.parentNode;
                        switch (event.which) {
                            case 9:
                                if (!spKikarOption.isNotClickable) {
                                    $scope.spKikarDropdown.toggleDropdown();
                                }
                                break;
                            case 27:
                                $scope.spKikarDropdown.toggleDropdown();
                                event.preventDefault();
                                event.stopImmediatePropagation();
                                break;
                            case 13:
                                event.preventDefault();
                                event.stopImmediatePropagation();
                                if (spKikarOption.href) {
                                    $location.path(spKikarOption.href);
                                }
                                if (spKikarOption.sref) {
                                    $state.go(spKikarOption.sref);
                                }

                                if (!spKikarOption.href && !spKikarOption.sref) {
                                    spKikarOption.click(event);
                                }

                                $scope.spKikarDropdown.toggleDropdown();
                                break;
                            case 40:
                                (_currentTarget.nextElementSibling || _parentElement.firstElementChild).querySelector('.label').focus();
                                event.preventDefault();
                                event.stopImmediatePropagation();
                                break;
                            case 38:
                                (_currentTarget.previousElementSibling || _parentElement.lastElementChild).querySelector('.label').focus();
                                event.preventDefault();
                                event.stopImmediatePropagation();
                                break;
                        }
                    }

                    $scope.$watch('spKikarDropdown.isOpen', function(isOpen) {
                        if (isOpen && spKikarOption.spSelected) {
                            $timeout(function () {
                                element[0].querySelector('.label').focus();
                            }, 20);
                        }
                    });

                }]
            };
        }]);
})(angular, app);
