(function (angular, app) {
    'use strict';

    app
        .config(['$stateProvider', 'ROUTE_ACCESS', function ($stateProvider, ROUTE_ACCESS) {
            addState('app.update-order', '/update-order', {
                order: ['Orders', function (Orders) {
                    return Orders.orderInEdit;
                }],
                user: ['User', function (user) {
                    return user.getData();
                }],
                updateType: ['UPDATE_ORDER_PAYMENT_TYPE', function (UPDATE_ORDER_PAYMENT_TYPE) {
                    return {
                        type: UPDATE_ORDER_PAYMENT_TYPE.EDIT_ORDER_UPDATE,
                        exitState: 'app.cart',
                        stateBase: 'app.update-order'
                    };
                }]
            });
            addState('app.user-confirmation', '/user-confirmation/update/:orderId?{userConfirmationToken}', {
                order: ['$stateParams', 'Api', function ($stateParams, Api) {
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/:rid/orders/' + $stateParams.orderId,
                        params: {
                            userConfirmationToken: $stateParams.userConfirmationToken
                        }
                    }).catch(function () {
                    });
                }],
                updateType: ['UPDATE_ORDER_PAYMENT_TYPE', function (UPDATE_ORDER_PAYMENT_TYPE) {
                    return {
                        type: UPDATE_ORDER_PAYMENT_TYPE.USER_CONFIRMATION_UPDATE,
                        exitState: 'app.home',
                        stateBase: 'app.user-confirmation'
                    };
                }]
            });

            function addState(stateName, url, resolve) {
                var routeAccess = [ROUTE_ACCESS.USER_VERIFIED];

                switch (stateName) {
                    case 'app.update-order':
                        routeAccess.push(ROUTE_ACCESS.NOT_EMPTY_CART);
                        break;
                    default:
                        break;
                }

                $stateProvider.state(stateName, {
                    url: url,
                    // abstract: true, TODO: redirection doesn't work with abstract:true, need to update ui-router
                    redirectTo: stateName + '.payment',
                    data: {
                        routeAccess: routeAccess,
                        name: 'update-order',
                        layout: {
                            menu: false,
                            search: false,
                            sidenav: false
                        }
                    },
                    resolve: resolve,
                    views: {
                        '@': {
                            templateUrl: 'template/views/update-order/index.html',
                            controller: 'UpdateOrderCtrl as updateOrderCtrl'
                        }
                    }
                });
            }
        }])
        .controller('UpdateOrderCtrl', [
            '$scope', '$rootScope', '$state', 'Cart', 'Orders', 'order', 'updateType', 'user', 'DataLayer',
            function ($scope, $rootScope, $state, Cart, Orders, order, updateType, user, DataLayer) {
                var updateOrderCtrl = this;
                updateOrderCtrl.order = order || Orders.orderInEdit || $rootScope.orderInEdit
                updateOrderCtrl.updateType = updateType;
                updateOrderCtrl.user = user;

                updateOrderCtrl.continueShopping = continueShopping;
                updateOrderCtrl.summaryData = {
                    paymentMethod: null,
                    storeCredit: '',
                    creditCard: {},
                    paymentsNumber: 1
                };

                function continueShopping() {
                    DataLayer.push(DataLayer.EVENTS.SELECT_CONTENT, {data: {category: 'Button', action: 'Click', label: 'Continue Shopping'}});
                    $rootScope.$emit('continueShopping', {});
                    $state.go('app.home');
                }

                // reset time travel on exiting checkout
                $scope.$on('$destroy', function () {
                    Cart.disableLoyalty(false);

                    if (Cart.timeTravel) {
                        Cart.setTimeTravel();
                        Cart.save();
                    }
                });
            }]);
})(angular, app);


