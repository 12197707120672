
(function (angular, app) {
    'use strict';

    app.directive('spPassword', [
        function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                spModel: '=',
                placeholder: '@?iPlaceholder',
                onBlur: "&"
            },
            template: function(element, attrs) {
                var inputAtrrs = '';
                angular.forEach(attrs.$attr, function(attr, key) {
                    if (key != 'spModel' && key != 'iType' && key != 'spShow' && key != 'iPlaceholder' && key != 'iSpInlineError' && attr.indexOf('ng-') !== 0) {
                        inputAtrrs += ' ' + attr.replace('i-', '').replace('sp-', 'ng-') + '="' + attrs[key] + '"';
                    }
                });
                return '<div class="sp-password">' +
                    '<div class="eye-icon" ng-show="spModel.length" ng-mousedown="show = true;" ng-mouseup="show = false;" ng-mouseleave="show = false;"></div>' +
                    '<input ng-show="!show" ng-model="spModel" ng-blur="onBlur()" id="' + attrs.iName + '" type="password"' + (attrs.iPlaceholder ? ' placeholder="{{placeholder}}"' : '') + inputAtrrs +
                    (angular.isString(attrs.iSpInlineError) ? ' sp-inline-error="' + attrs.iSpInlineError + '"' : '') +
                    (attrs.iSpInlineErrorAttachToParent ? ' sp-inline-error-attach-to-parent="' + attrs.iSpInlineErrorAttachToParent + '"' : '') + '/>' +
                    '<input ng-show="show" ng-model="spModel" type="text"/>' +
                    '</div>';
            }
        };
    }]);

})(angular, app);