(function (angular) {
    'use strict';

    var ORDER_STATUSES = {
        NEW: 1,
        CHARGED: 2,
        DELIVERED: 3,
        CANCELLED: 4,
        SUSPENDED: 5,
        PROCESSING: 6,
        COLLECTED: 7,
        REGISTERED: 8,
        WAITING_FOR_CONFIRMATION: 117,
        USER_CONFIRMED: 118
    };
    angular.forEach(ORDER_STATUSES, function (value, key) {
        ORDER_STATUSES[value] = key;
    });

    var ORDER_STATUS_STAGES = {
        RECEIVED: {},
        IN_PROCESS: {},
        READY: {},
        FINISH: {},
        CANCELLED: {},
    };
    ORDER_STATUS_STAGES.RECEIVED[ORDER_STATUSES.NEW] = ORDER_STATUSES[ORDER_STATUSES.NEW];
    ORDER_STATUS_STAGES.READY[ORDER_STATUSES.CHARGED] = ORDER_STATUSES[ORDER_STATUSES.CHARGED];
    ORDER_STATUS_STAGES.FINISH[ORDER_STATUSES.DELIVERED] = ORDER_STATUSES[ORDER_STATUSES.DELIVERED];
    ORDER_STATUS_STAGES.CANCELLED[ORDER_STATUSES.CANCELLED] = ORDER_STATUSES[ORDER_STATUSES.CANCELLED];
    ORDER_STATUS_STAGES.IN_PROCESS[ORDER_STATUSES.SUSPENDED] = ORDER_STATUSES[ORDER_STATUSES.SUSPENDED];
    ORDER_STATUS_STAGES.IN_PROCESS[ORDER_STATUSES.PROCESSING] = ORDER_STATUSES[ORDER_STATUSES.PROCESSING];
    ORDER_STATUS_STAGES.IN_PROCESS[ORDER_STATUSES.COLLECTED] = ORDER_STATUSES[ORDER_STATUSES.COLLECTED];
    ORDER_STATUS_STAGES.IN_PROCESS[ORDER_STATUSES.REGISTERED] = ORDER_STATUSES[ORDER_STATUSES.REGISTERED];
    ORDER_STATUS_STAGES.IN_PROCESS[ORDER_STATUSES.WAITING_FOR_CONFIRMATION] = ORDER_STATUSES[ORDER_STATUSES.WAITING_FOR_CONFIRMATION];
    ORDER_STATUS_STAGES.IN_PROCESS[ORDER_STATUSES.USER_CONFIRMED] = ORDER_STATUSES[ORDER_STATUSES.USER_CONFIRMED];

    angular.module('spConstants', [])
        .constant('FRONTEND_SERVICE_ID', 4)
        .constant('CONFIRM_PASSWORD', 'confirm_password')
        .constant('ROUTE_ACCESS', {
            LOGIN: 'login',
            LOGOUT: 'logout',
            USER_VERIFIED: 'userverified',
            EMPTY_CART: 'emptycart',
            NOT_EMPTY_CART: 'notemptycart',
            NOT_EMPTY_CART_ACTUAL_LINES: 'notemptycartactuallines',
            ORGANIZATION_ADMIN: 'organizationadmin',
            NOT_ORGANIZATION_MEMBER: 'notorganizationmember'
        })
        .constant('PHONE_TYPES', {
            HOME: 1,
            WORK: 2,
            MOBILE: 3,
            VERIFY: 4
        })
        .constant('ORDER_STATUSES', ORDER_STATUSES)
        .constant('ORDER_STATUS_STAGES', ORDER_STATUS_STAGES)
        .constant('ORDER_TYPES', {
            REGULAR: 1,
            QBUSTER: 2,
            CUSTOMER_SERVICE: 3
        })
        .constant('POS_USER_FIELD_TYPES', {
            PHONE: 0,
            EMAIL: 1
        })
        .constant('CREDIT_CARD_MODES', {
            REMEMBER_CARDS: 'TOKEN',
            ONE_TIME: 'ONE_TIME'
        })
        .constant('CREDIT_CARD_3DS_MODES', {
            ON_ADD_CARD: 1,
            ON_PAYMENT: 2,
            ON_UPDATE: 3
        })
        .constant('CREDIT_CARD_TYPES', {
            REGULAR: 0,
            TOURIST_CARD: 1,
            DIRECT: 3,
            GIFT_CARD: 4
        })
        .constant('ANALYTICS_CURRENCY', {
            '$': 'USD',
            '₪': 'ILS',
            '€': 'EUR',
            'KWD': 'KWD',
            '£': 'GBP'
        })
        .constant('SUBSTITUTE_PREFERENCES', {
            ALLOW: 1,
            CALL_ME: 2,
            DO_NOT_ALLOW: 3,
            SEND_SMS: 4,
            WHATSAPP: 5
        })
        .constant('DELIVERY_TIMES_TYPES', {
            REGULAR: 1,
            DELIVERY_WITHIN_HOURS: 2,
            DELIVERY_WITHIN_DAYS: 3
        })
        .constant('BRANCH_AREA_TYPES', {
            LOCATIONS: 1,
            ZIP_CODES: 2,
            POLYGONS: 3,
            EVERYWHERE: 4,
            PICKUP: 5
        })
        .constant('TICKET_STATUSES', {
            NEW: 1,
            HANDLING: 2,
            DONE: 3
        })
        .constant('CATEGORIES_TYPES', {
            INHERIT: 1,
            REGULAR: 2,
            MENU: 3,
            LARGE_ITEMS: 4
        })
        .constant('SP_ITEMS_DESIGNS', {
            REGULAR: 1,
            MENU: 2
        })
        .constant('NEWSLETTER_POPUP_BEHAVIOR', {
            ENTER_SITE: 1,
            BUTTON: 2
        })
        .constant('CREDIT_CARD_PROVIDERS', {
            MERCURY: 'Mercury',
            PELE_CARD: 'PeleCard',
            FIRST_DATA: 'FirstData',
            FIDELITY: 'Fidelity',
            AUTHORIZE_DOT_NET: 'AuthorizeDotNet',
            CREDIT_GUARD: 'CreditGuard',
            CREDIT_GUARD_3DS: 'CreditGuard3DS',
            CREDIT_GUARD_3DS_V2: 'CreditGuard3DSV2',
            STRIPE: 'Stripe',
            COMAX: 'Comax',
            BARCLAY_CARD: 'BarclayCard'
        })
        .constant('DELIVERY_AREA_METHODS_PRECISION', {
            PRECISE_LOCATION: 1,
            CITY: 2,
            CITY_STREET_NOT_REQUIRED: 3
        })
        .constant('LINK_LOCATIONS', {
            BOTTOM: 0,
            TOP: 1
        })
        .constant('CITY_TYPES', {
            ONE_ZIP_CODE: 1,
            MULTIPLE_ZIP_CODES: 2
        })
        .constant('HOUSE_ENTRIES', [
            'HOUSE_ENTRY_NONE',
            'HOUSE_ENTRY_A',
            'HOUSE_ENTRY_B',
            'HOUSE_ENTRY_C',
            'HOUSE_ENTRY_D',
            'HOUSE_ENTRY_E',
            'HOUSE_ENTRY_F',
            'HOUSE_ENTRY_G',
            'HOUSE_ENTRY_H',
            'HOUSE_ENTRY_I',
            'HOUSE_ENTRY_J'
        ])
        .constant('GENDERS', {
            MALE: 1,
            FEMALE: 2,
            PREFER_NOT_TO_SAY: 3
        })
        .constant('RETAILER_PROMOTION_EVENTS', {
            REGISTER: 1,
            CHECKOUT: 2,
            ARRIVAL: 3
        })
        .constant('HOME_PAGE_CAROUSEL_DISPLAY_TYPES', {
            PROMOTIONS: 1,
            PRODUCTS: 2
        })
        .constant('HOME_PAGE_SIDE_DISPLAY_TYPES', {
            TOP: 1,
            BOTTOM: 2,

            1: 'Top',
            2: 'Bottom'
        })
        .constant('ADVERTISEMENT_AD_TYPE', {
            BANNER: 1,
            VIDEO: 2,
            RECIPE: 3,
            PLASMA: 4,
            LANDING_PAGE: 5,
            CHECKOUT_SUGGESTIONS: 6
        })
        .constant('ADVERTISEMENT_GROUP_TYPE', {
            BANNERS: 1,
            PRODUCTS: 2,
            FILLERS: 3,
            PLASMA: 4
        })
        .constant('ADVERTISEMENT_CONVERSION_TYPE', {
            CHARGE: 2,
            ADD_TO_CART: 800
        })
        .constant('PRODUCT_PAGE_CAROUSEL_CONTENT', {
            SIMILAR_PRODUCTS: 1,
            SPECIAL_PRODUCTS: 2
        })
        .constant('WEB_PAGE_LOCATIONS', {
            HEADER: 1,
            FOOTER: 2
        })
        .constant('CHARGE_SPECIALS_CALCULATION_TIME', {
            COLLECTION_TIME: null,
            CHECKOUT_POS: '2',
            CHECKOUT_SP: '3'
        })
        .constant('URL_DIALOG_DATA_QUERY_PARAMS', {
            LOGIN: ['loginDialogFromLoyalty', 'resetPasswordCode', 'email', 'registrationTab', 'registrationEmail'],
            LOYALTY: ['classes', 'fireAndForgot']
        })
        .constant('RETAILER_STATUSES', {
            IN_MAINTENANCE: 0,
            REGISTERED_ONLY: 1,
            ACTIVE: 2,
            DISABLED: 3,
            ORGANIZATION_ONLY: 4
        })
        .constant('ORDER_UPDATE_POLICY', {
            ORDER_STATUS: 1,
            HOURS_BEFORE_TIMESLOT: 2,
        })
        .constant('LOYALTY_CLUB_DRIVERS', {
            COMPANY: 1,
            RETALIX: 2,
            LOCAL_LOYALTY: 3,
            CLUB_365: 4,
            RETALIX_V2: 6,
            MAX: 7,
            COMAX: 8,
            SIMPLETEXT: 9,
            LOOPBACK: 10,
            ISRACARD: 11,
            BIRDZI: 12,
            EOECOLLEAGUE: 13,
            EOECOOP: 14,
            CHIPPER: 15,
            TIV_TAAM_LOYALTY: 16,
            NCR_MULTIPASS_CASHBACK: 17
        })
        .constant('SPECIAL_DISCOUNT_TYPES', {
            MULTIPASS: 1
        })
        .constant('STATE_NAMES', {
            HOME: 'app.home'
        })
        .constant('SUPERVISION_TAGS', {
            1: 'Chametz',
            2: 'PassoverKosher',
            3: 'PassoverKosherTemporarily',
            4: 'PassoverKitniyotKosher',
            5: 'PassoverKitniyotKosherTemporarily',
            6: 'Meat',
            7: 'Milky',
            8: 'Pareve',
            9: 'IsraelMilk',
            10: 'IsraelCooking',
            11: 'SabbathObservingFactory',
            12: 'NoShviitConcern',
            13: 'NoOrlaConcern',
            14: 'NoTevelConcern',
            15: 'NoOvernightConcern',
            16: 'NoGeniza'
        })
        .constant('SEARCH_SORT_BOOST_TYPES', {
            A_B: 1,
            BRAND: 2,
            SUB_SUB_CATEGORY: 3,
            SUPPLIER: 4,
            POS_SOLD_QUANTITY: 5,
            PRICE: 6,
            HAS_SPECIAL: 7,
            USER_LAST_PURCHASED_PRODUCTS: 8, // not a category sort type
            PRODUCT_TAGS: 9 // not a category sort type
        })
        .constant('IMAGE_TYPES', {
            IMAGE: 1,
            IMAGE360: 2
        })
        .constant('USER_VERIFICATION_STATUS', {
            NOT_VERIFIED: 1,
            SENT: 2,
            VERIFIED: 3
        })
        .constant('CART_LINE_TYPES', {
            MARKETING: 6
        })
        .constant('ORGANIZATIONS_ROLES', {
            USER: 1,
            ADMIN: 2
        })
        .constant('LINE_STATUS', {
            STATUS: -1,
            NEW: 1,
            DONE: 2,
            MISSING: 3,
            ON_HOLD: 4,
            REPLACED: 5,
            BUNDLED: 6,
            REFUND: 7
        })
        .constant('ORGANIZATION_TYPES', {
            SIMPLE: 1,
            OBLIGO: 2,
            NO_OBLIGO: 3
        })
        .constant('POLICY_ENFORCEMENT_MODES', {
            IGNORE: 1,
            SHOW: 2,
            FORCE: 3
        })
        .constant('UPDATE_ORDER_PAYMENT_TYPE', {
            EDIT_ORDER_UPDATE: 1,
            USER_CONFIRMATION_UPDATE: 2
        })
        .constant('USER_CREDIT_LOG_ACTION_TYPES', {
            BACKEND_CREDIT_UPDATE: {id: 1, name: 'User Credit Update'},
            BACKEND_DATA_UPDATE: {id: 2, name: 'Data Update'},
            BACKEND_USER_ADDED: {id: 3, name: 'Set as Credit Customer'},
            BACKEND_USER_REMOVED: {id: 4, name: 'Unset Credit Customer'},
            BACKEND_ORDER_CANCELED: {id: 5, name: 'Order Canceled in Backend'},
            BACKEND_ORDER_CHANGED: {id: 6, name: 'Order Changed in Backend'},
            BACKEND_ORDER_RENEWED: {id: 7, name: 'Order Renewed in Backend'},
            FRONTEND_CHECKOUT: {id: 8, name: 'User Checkout'},
            FRONTEND_ORDER_EDIT: {id: 9, name: 'User Order Edit'},
            COLLECTION_APP_CHANGED: {id: 10, name: 'Collection App Edit'},
            COLLECTION_APP_CANCELED: {id: 11, name: 'Collection App Canceled'},
            COLLECTION_APP_FINISHED: {id: 12, name: 'Collection App Finished'},
            POS_CHANGES: {id: 13, name: 'Changed by POS'},
            REFUND_ORDER: {id: 14, name: 'Order Refunded'},
            ORGANIZATION_USER_ADDED: {id: 15, name: 'User added to Organization'},
            ORGANIZATION_USER_REMOVED: {id: 16, name: 'User Removed from Organization'},
            ORGANIZATION_CREATED: {id: 17, name: 'Organization Created'},
            ORGANIZATION_DATA_CHANGED: {id: 18, name: 'Organization Data Changed'},
            ORGANIZATION_ACTIVATED: {id: 19, name: 'Organization Activated'},
            ORGANIZATION_DEACTIVATED: {id: 20, name: 'Organization Deactivated'},
            ORGANIZATION_OBLIGO_CHANGED: {id: 21, name: 'Organization Obligo Changed'}
        })
        .constant('RECEIPT_TYPE_ID', {
            DIGITAL: 1,
            BOTH: 2,
            PHYSICAL: 3
        })
        .constant('DEFAULT_AFTER_FIRST_ORDER', {
            DEFAULT: 1,
            CUSTOMER_LAST_CHOICE: 2
        })
        .constant('COUPONS_PAGE_DISPLAY', {
            NORMAL: 1,
            GROUPED: 2,
        })
        .constant('LAST_MINUTE_RECOMMENDATIONS', {
            SECTIONS_TYPES: {
                OFFERS: 1,
                TIP_DONATION: 2,
                COUPONS: 3,
                PRODUCTS_USUALLY: 4,
                PRODUCTS_COMPLEMENTARY: 5
            },
            ITEMS_TYPES: {
                SPECIALS: 1,
                PRODUCTS: 2
            }
        })
        .constant('LOGIN_WITH_OTP_OPTIONS', {
            EMAIL: 1,
            PHONE_NUMBER: 2,
            VERIFIED_PHONE_CREDENTIAL: 100
        })
        .constant('LOGIN_OR_REGISTER_SCREENS', {
            DEFAULT: 'default',
            SELECT_OTP_OPTION: 'selectOtpOption',
            ENTER_OTP_PHONE_OR_EMAIL: 'enterOtpPhoneOrEmail',
            ENTER_CODE_TO_VALIDATE: 'enterCodeToValidate',
            OTP_ERROR: 'otpError',
            FORGOTTEN_PASSWORD: 'forgottenPassword',
            FORGOTTEN_PASSWORD_SENT: 'forgottenPasswordSent',
            RESET_PASSWORD: 'resetPassword',
            RESET_PASSWORD_ERROR: 'resetPasswordError',
            ENTER_COMPLETION_FORM: "enterCompletionForm",
            CHOOSE_UNIQUE_EMAIL: 'chooseUniqueEmail',
            ENTER_FACEBOOK_REGISTRATION_FORM: 'enterFacebookRegistrationForm'
        })
        .constant('USER_TYPE_FOR_COMPLETION_FORM', {
            NEW_USER: 'newUser',
            SITE_USER_BUT_NOT_LOYALTY_MEMBER: 'siteUserButNotLoyaltyMember',
            LOYALTY_MEMBER_BUT_NOT_SITE_MEMBER: 'loyaltyMemberButNotSiteMember',
        })
        .constant('COUPON_MEANINGLESS_REMAINING_VALUES', [0.1, 0.01, 0.001])
        .constant('LOYALTY_PAGE_METHODS', {
/*
            LOYALTY_MEMBER: 1,
*/
            NON_LOYALTY_MEMBER: 0
        })
        .constant('LOYALTY_PAGE_COMPONENT_TYPES', {
            BY_NAME: {
                MAIN_BANNER: 1,
                MARKETING_BANNER: 2,
                STORES_COMPONENT: 3,
                POINTS_TO_CASHBACK_CALCULATOR: 4,
                EXTERNAL_SERVICES_LIST: 5,
                POTENTIAL_PERSONAL_COUPONS: 6,
                LOYALTY_SPECIALS_CAROUSEL: 7,
                SIGN_IN_COMPONENT: 8,
                Q_AND_A_COMPONENT: 9,
                LEGAL_TEXT: 10,
                POINTS_ACCUMULATION_PROGRESS_BAR: 11,
                PERSONALIZED_COUPONS_CAROUSEL: 12,
                MEMBER_MAIN_BANNER: 13,
                ACCUMULATION_REDEMPTION: 14
            } ,
            BY_VALUE: {
                1: {
                    disabled: false,
                    isSortable: false,
                    title: 'Main Banner',
                    isMandatory: true,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/main-banner.html'
                } ,
                2: {
                    disabled: false ,
                    isSortable: true,
                    title: 'Marketing Banner',
                    isMandatory: true,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/marketing-banner.html'
                } ,
                3: {
                    disabled: false,
                    isSortable: true,
                    title: 'Stores component',
                    isMandatory: true,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/stores-component.html',
                    isGeneral: true
                } ,
                4: {
                    disabled: false,
                    isSortable: true,
                    title: 'Points to Cashback Calculator',
                    isMandatory: false,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/points-to-cash-calculator.html'
                } ,
                5: {
                    disabled: false,
                    isSortable: true,
                    title: 'External Services List',
                    isMandatory: false,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/external-services-list.html',
                    isGeneral: true
                } ,
                6: {
                    disabled: false,
                    isSortable: true,
                    title: 'Potential Personal Coupons - Ad image',
                    isMandatory: true,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/potential-coupons-component.html',
                } ,
                7: {
                    disabled: false,
                    isSortable: true,
                    title: 'Loyalty specials carousel',
                    isMandatory: true,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/loyalty-specials-carousel.html',
                    isGeneral: true
                } ,
                8: {
                    disabled: false,
                    isSortable: false,
                    title: 'Sign-in / Sign-up Component',
                    isMandatory: true,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/sign-in-component.html'
                } ,
                9: {
                    disabled: false,
                    isSortable: false,
                    isGeneral: true,
                    title: 'Questions and Answers Component',
                    isMandatory: false,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/q-and-a-component.html',
                } ,
                10: {
                    disabled: false,
                    isSortable: false,
                    isGeneral: true,
                    title: 'Legal Text',
                    isMandatory: false,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/legal-text-component.html'
                } ,
                11: {
                    disabled: false,
                    isSortable: true,
                    title: 'Points Accumulation Progress Bar',
                    isMandatory: false,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/points-accumulation-progress-bar.html'
                } ,
                12: {
                    disabled: false,
                    isSortable: true,
                    title: 'Personalized Coupons Component',
                    isMandatory: true,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/personalized-coupons-component.html'
                } ,
                13: {
                    disabled: false,
                    isSortable: false,
                    title: 'Main Banner (web only)',
                    isMandatory: true,
                    templateUrl: 'template/views/user-edit/premium-loyalty-club/components/member-main-banner.html'
                }
            }
        })
        .constant('USER_ERRORS', {
            NOT_LOGGED_IN: 'not login on this session',
        }).constant('BRANCH_DELIVERY_TYPES', {
            DELIVERY: 1,
            PICKUP: 2,
            PICK_AND_GO: 3,
            SCAN_AND_GO: 4,
            EXPRESS_DELIVERY: 5
        })
        .constant('USER_LOYALTY_TYPES', {
            ELIGIBLE_TO_REDEEM: 1,
            NOT_ELIGIBLE_TO_REDEEM: 2,
            NOT_REGISTER: 3,
            POINT_IS_ZERO: 4
        })
        .constant("PRODUCT_DISPLAY", {
            UNIT: {
                id: 1,
                name: 'Unit'
            },
            WEIGHT: {
                id: 2,
                name: 'Weight'
            },
            WEIGHT_AND_UNIT: {
                id: 3,
                name: 'Weight and Unit'
            }
        }).constant('GOOGLE_MAP_ADDRESS_TYPES', {
            ROUTE: 'route',
            PREMISE: 'premise',
            COUNTRY: 'country',
            LOCALITY: 'locality',
            ZIP_CODE: 'postal_code',
            POSTAL_TOWN: 'postal_town',
            SUB_LOCALITY: 'sublocality',
            NEIGHBORHOOD: 'neighborhood',
            STREET_NUMBER: 'street_number',
            ESTABLISHMENT: 'establishment',
            ADMIN_AREA_LV1: 'administrative_area_level_1',
            ADMIN_AREA_LV2: 'administrative_area_level_2',
        }).constant('LANGUAGES', {
            he: 1,
            en: 2,
            es: 3,
            ru: 4,
            fr: 5,
            ar: 6,
        }).constant('TAG_DATE_CONFIGURATION_TYPE', {
        WEEK_DAYS: 1,
        DATE_RAGE: 2,
    })
        .run(['$rootScope', 'PHONE_TYPES', 'CONFIRM_PASSWORD', 'ORDER_STATUSES', 'ORDER_STATUS_STAGES', 'POS_USER_FIELD_TYPES',
            'SUBSTITUTE_PREFERENCES', 'DELIVERY_TIMES_TYPES', 'BRANCH_AREA_TYPES', 'LANGUAGES',
            'CATEGORIES_TYPES', 'SP_ITEMS_DESIGNS', 'CREDIT_CARD_PROVIDERS', 'DELIVERY_AREA_METHODS_PRECISION',
            'LINK_LOCATIONS', 'CITY_TYPES', 'GENDERS', 'HOUSE_ENTRIES', 'RETAILER_PROMOTION_EVENTS',
            'CREDIT_CARD_TYPES', 'HOME_PAGE_CAROUSEL_DISPLAY_TYPES', 'HOME_PAGE_SIDE_DISPLAY_TYPES',
            'PRODUCT_PAGE_CAROUSEL_CONTENT', 'ORDER_TYPES', 'WEB_PAGE_LOCATIONS', 'CHARGE_SPECIALS_CALCULATION_TIME',
            'ANALYTICS_CURRENCY', 'ADVERTISEMENT_AD_TYPE', 'SUPERVISION_TAGS', 'IMAGE_TYPES', 'CREDIT_CARD_3DS_MODES',
            'CART_LINE_TYPES', 'USER_VERIFICATION_STATUS', 'ORGANIZATIONS_ROLES', 'ORGANIZATION_TYPES',
            'POLICY_ENFORCEMENT_MODES', 'RETAILER_STATUSES', 'LINE_STATUS', 'ORDER_UPDATE_POLICY', 'LOYALTY_CLUB_DRIVERS', 'RECEIPT_TYPE_ID', 'DEFAULT_AFTER_FIRST_ORDER',
            'COUPONS_PAGE_DISPLAY', 'LAST_MINUTE_RECOMMENDATIONS', 'LOGIN_WITH_OTP_OPTIONS', 'LOGIN_OR_REGISTER_SCREENS', 'USER_TYPE_FOR_COMPLETION_FORM', 'COUPON_MEANINGLESS_REMAINING_VALUES',
            'LOYALTY_PAGE_METHODS', 'LOYALTY_PAGE_COMPONENT_TYPES', 'USER_ERRORS', 'PRODUCT_DISPLAY', 'BRANCH_DELIVERY_TYPES', 'USER_LOYALTY_TYPES', 'GOOGLE_MAP_ADDRESS_TYPES', 'TAG_DATE_CONFIGURATION_TYPE',
            function ($rootScope, PHONE_TYPES, CONFIRM_PASSWORD, ORDER_STATUSES, ORDER_STATUS_STAGES, POS_USER_FIELD_TYPES,
                      SUBSTITUTE_PREFERENCES, DELIVERY_TIMES_TYPES, BRANCH_AREA_TYPES, LANGUAGES,
                      CATEGORIES_TYPES, SP_ITEMS_DESIGNS, CREDIT_CARD_PROVIDERS, DELIVERY_AREA_METHODS_PRECISION,
                      LINK_LOCATIONS, CITY_TYPES, GENDERS, HOUSE_ENTRIES, RETAILER_PROMOTION_EVENTS,
                      CREDIT_CARD_TYPES, HOME_PAGE_CAROUSEL_DISPLAY_TYPES, HOME_PAGE_SIDE_DISPLAY_TYPES,
                      PRODUCT_PAGE_CAROUSEL_CONTENT, ORDER_TYPES, WEB_PAGE_LOCATIONS, CHARGE_SPECIALS_CALCULATION_TIME,
                      ANALYTICS_CURRENCY, ADVERTISEMENT_AD_TYPE, SUPERVISION_TAGS, IMAGE_TYPES, CREDIT_CARD_3DS_MODES,
                      CART_LINE_TYPES, USER_VERIFICATION_STATUS, ORGANIZATIONS_ROLES, ORGANIZATION_TYPES,
                      POLICY_ENFORCEMENT_MODES, RETAILER_STATUSES, LINE_STATUS, ORDER_UPDATE_POLICY, LOYALTY_CLUB_DRIVERS, RECEIPT_TYPE_ID, DEFAULT_AFTER_FIRST_ORDER,
                      COUPONS_PAGE_DISPLAY, LAST_MINUTE_RECOMMENDATIONS, LOGIN_WITH_OTP_OPTIONS, LOGIN_OR_REGISTER_SCREENS, USER_TYPE_FOR_COMPLETION_FORM, COUPON_MEANINGLESS_REMAINING_VALUES,
                      LOYALTY_PAGE_METHODS, LOYALTY_PAGE_COMPONENT_TYPES, USER_ERRORS, PRODUCT_DISPLAY,
                      BRANCH_DELIVERY_TYPES, USER_LOYALTY_TYPES, GOOGLE_MAP_ADDRESS_TYPES, TAG_DATE_CONFIGURATION_TYPE) {
                $rootScope.PHONE_TYPES = PHONE_TYPES;
                $rootScope.CONFIRM_PASSWORD = CONFIRM_PASSWORD;
                $rootScope.ORDER_STATUSES = ORDER_STATUSES;
                $rootScope.ORDER_STATUS_STAGES = ORDER_STATUS_STAGES;
                $rootScope.POS_USER_FIELD_TYPES = POS_USER_FIELD_TYPES;
                $rootScope.SUBSTITUTE_PREFERENCES = SUBSTITUTE_PREFERENCES;
                $rootScope.DELIVERY_TIMES_TYPES = DELIVERY_TIMES_TYPES;
                $rootScope.BRANCH_AREA_TYPES = BRANCH_AREA_TYPES;
                $rootScope.CATEGORIES_TYPES = CATEGORIES_TYPES;
                $rootScope.LANGUAGES = LANGUAGES;
                $rootScope.SP_ITEMS_DESIGNS = SP_ITEMS_DESIGNS;
                $rootScope.CREDIT_CARD_PROVIDERS = CREDIT_CARD_PROVIDERS;
                $rootScope.DELIVERY_AREA_METHODS_PRECISION = DELIVERY_AREA_METHODS_PRECISION;
                $rootScope.LINK_LOCATIONS = LINK_LOCATIONS;
                $rootScope.CITY_TYPES = CITY_TYPES;
                $rootScope.GENDERS = GENDERS;
                $rootScope.HOUSE_ENTRIES = HOUSE_ENTRIES;
                $rootScope.RETAILER_PROMOTION_EVENTS = RETAILER_PROMOTION_EVENTS;
                $rootScope.CREDIT_CARD_TYPES = CREDIT_CARD_TYPES;
                $rootScope.HOME_PAGE_CAROUSEL_DISPLAY_TYPES = HOME_PAGE_CAROUSEL_DISPLAY_TYPES;
                $rootScope.HOME_PAGE_SIDE_DISPLAY_TYPES = HOME_PAGE_SIDE_DISPLAY_TYPES;
                $rootScope.PRODUCT_PAGE_CAROUSEL_CONTENT = PRODUCT_PAGE_CAROUSEL_CONTENT;
                $rootScope.ORDER_TYPES = ORDER_TYPES;
                $rootScope.WEB_PAGE_LOCATIONS = WEB_PAGE_LOCATIONS;
                $rootScope.CHARGE_SPECIALS_CALCULATION_TIME = CHARGE_SPECIALS_CALCULATION_TIME;
                $rootScope.ANALYTICS_CURRENCY = ANALYTICS_CURRENCY;
                $rootScope.ADVERTISEMENT_AD_TYPE = ADVERTISEMENT_AD_TYPE;
                $rootScope.SUPERVISION_TAGS = SUPERVISION_TAGS;
                $rootScope.IMAGE_TYPES = IMAGE_TYPES;
                $rootScope.CREDIT_CARD_3DS_MODES = CREDIT_CARD_3DS_MODES;
                $rootScope.USER_VERIFICATION_STATUS = USER_VERIFICATION_STATUS;
                $rootScope.CART_LINE_TYPES = CART_LINE_TYPES;
                $rootScope.ORGANIZATIONS_ROLES = ORGANIZATIONS_ROLES;
                $rootScope.ORGANIZATION_TYPES = ORGANIZATION_TYPES;
                $rootScope.POLICY_ENFORCEMENT_MODES = POLICY_ENFORCEMENT_MODES;
                $rootScope.RETAILER_STATUSES = RETAILER_STATUSES;
                $rootScope.LINE_STATUS = LINE_STATUS;
                $rootScope.ORDER_UPDATE_POLICY = ORDER_UPDATE_POLICY;
                $rootScope.LOYALTY_CLUB_DRIVERS = LOYALTY_CLUB_DRIVERS;
                $rootScope.RECEIPT_TYPE_ID = RECEIPT_TYPE_ID;
                $rootScope.DEFAULT_AFTER_FIRST_ORDER = DEFAULT_AFTER_FIRST_ORDER;
                $rootScope.COUPONS_PAGE_DISPLAY = COUPONS_PAGE_DISPLAY;
                $rootScope.LAST_MINUTE_RECOMMENDATIONS = LAST_MINUTE_RECOMMENDATIONS;
                $rootScope.LOGIN_WITH_OTP_OPTIONS = LOGIN_WITH_OTP_OPTIONS;
                $rootScope.LOGIN_OR_REGISTER_SCREENS = LOGIN_OR_REGISTER_SCREENS;
                $rootScope.USER_TYPE_FOR_COMPLETION_FORM = USER_TYPE_FOR_COMPLETION_FORM;
                $rootScope.COUPON_MEANINGLESS_REMAINING_VALUES = COUPON_MEANINGLESS_REMAINING_VALUES
                $rootScope.LOYALTY_PAGE_METHODS = LOYALTY_PAGE_METHODS;
                $rootScope.LOYALTY_PAGE_COMPONENT_TYPES = LOYALTY_PAGE_COMPONENT_TYPES;
                $rootScope.USER_ERRORS = USER_ERRORS;
                $rootScope.PRODUCT_DISPLAY = PRODUCT_DISPLAY;
                $rootScope.BRANCH_DELIVERY_TYPES = BRANCH_DELIVERY_TYPES;
                $rootScope.USER_LOYALTY_TYPES = USER_LOYALTY_TYPES;
                $rootScope.GOOGLE_MAP_ADDRESS_TYPES = GOOGLE_MAP_ADDRESS_TYPES;
                $rootScope.TAG_DATE_CONFIGURATION_TYPE = TAG_DATE_CONFIGURATION_TYPE;
            }]);
})(angular);
