
(function (angular, app) {
    'use strict';

    app.controller('MainCtrl', [
        '$scope', '$rootScope', '$state', '$location', '$filter', '$window', '$timeout', '$q', 'LocalStorage', 'Util',
        'Api', 'Config', 'Dialog', 'User', 'Cart', 'Orders', 'SpCartService', 'ChooseAreaDialog',
        'UserVerificationDialog', 'ROUTE_ACCESS', 'SP_UNITS', 'SP_SERVICES', 'ORGANIZATIONS_ROLES', 'ORGANIZATION_TYPES',
        function ($scope, $rootScope, $state, $location, $filter, $window, $timeout, $q, localStorageService, Util,
                  api, Config, dialog, user, cart, orders, spCartService, ChooseAreaDialog,
                  UserVerificationDialog, ROUTE_ACCESS, SP_UNITS, SP_SERVICES, ORGANIZATIONS_ROLES, ORGANIZATION_TYPES) {
            var _nameFilter = $filter('name'),
                _translateFilter = $filter('translate');

            $rootScope.defaultWeightUnit = {
                id: Config.retailer.defaultWeightUnitId,
                names: Config.retailer.defaultWeightUnitNames,
                unit: SP_UNITS.BY_NAME[_nameFilter(Config.retailer.defaultWeightUnitNames).toLowerCase()]
            };

            var searchParams = $location.search();


            if (!!searchParams.inviteId && !!searchParams.promotionId) {
                localStorageService.setItem('inviteId', searchParams.inviteId);
                localStorageService.setItem('promotionId', searchParams.promotionId);

                $location.search('inviteId', null);
                $location.search('promotionId', null);

                Util.openUserPromotionDialog(Config.retailer.promotion);
            }

            if (Util.isRetailerPromotionActive() && Config.retailer.promotion.popupOnEnterSite) {
                Util.openPromotionDialog(true);
            }

            dialog.defaults.closeIconTemplate = '<button class="close-dialog-corner-button no-design" ng-click="$dialog.hide()"><img class="clickable close-dialog-corner" src="https://d226b0iufwcjmj.cloudfront.net/global/frontend-icons/close-popup.png" alt="{{\'close\' | translate}}"/></button>';

            $scope.isRequesting = api.isRequesting;
            $scope.config = Config;

            $rootScope.util = Util;
            $rootScope.cart = cart;

            var $html = angular.element(document.querySelector('html'));
            $html.bind('keydown', function (event) {
                var tag = document.activeElement && document.activeElement.tagName.toLowerCase(),
                    code = event.which;

                if (tag === 'input' || tag === 'textarea' || (code >= 0 && code <= 47) || code == 91 || code == 93 ||
                    (code >= 112 && code <= 123) || code == 182 || code == 183 || code == 91 || code == 224) {
                    return;
                }

                var searchInput = document.querySelector('header .search input');
                if (searchInput && !event.ctrlKey && !event.altKey && (window ? !window.location.href.includes('?loginOrRegister') : true)) {
                    searchInput.focus();
                }
            });

            var lastActiveElementSelector = localStorageService.getItem('lastActiveElementSelector');
            if (lastActiveElementSelector) {
                $timeout(function () {
                    var lastActiveElement = document.querySelector(lastActiveElementSelector);
                    lastActiveElement && lastActiveElement.focus();
                    localStorageService.removeItem('lastActiveElementSelector');
                }, 5000);
            }

            function _setNagishLiElementAttributes() {
                $timeout(function () {
                    var nagishLiBarStrip = document.querySelector('nagishli#NagishLiTag > div#NagishLiBar > div#NagishLiBarStrip');
                    if (!!nagishLiBarStrip) {
                        nagishLiBarStrip.setAttribute('role', 'button');
                        nagishLiBarStrip.setAttribute('aria-label', _translateFilter('Accessibility component'));
                        nagishLiBarStrip.setAttribute('aria-expanded', nagishLiBarStrip.classList.contains('open'));

                        var nagishLiBarStripEl = angular.element(nagishLiBarStrip);
                        nagishLiBarStripEl.bind('click', function() {
                            if (nagishLiBarStripEl.attr('aria-expanded') === 'false') {
                                nagishLiBarStripEl.attr('aria-expanded', 'true');
                            } else {
                                nagishLiBarStripEl.attr('aria-expanded', 'false');
                            }
                        });
                    }

                    if (!document.querySelector('#NagishLiTabbingStyle')) {
                        var clickElement = document.querySelector('nagishli#NagishLiTag > div#NagishLiBar > #NagishLiMenus #NagishLiMisc #NLKBNav');
                        !!clickElement && clickElement.click();
                    }

                    $timeout(function () {
                        var styleElement = document.getElementById('NagishLiTabbingStyle');
                        styleElement && (styleElement.innerHTML = '');
                    }, 200);

                    var listItemEl = document.querySelectorAll('#NagishLiMenus > li.dropdown');
                    if(!!listItemEl) {
                        listItemEl.forEach(function(element) {
                            var hasAlt = element.hasAttribute('alt');
                            if (hasAlt) {
                                element.setAttribute('alt', '');
                            }
                            element.setAttribute('role', 'menuitem');
                            element.setAttribute('aria-haspopup', 'true');
                            element.setAttribute('aria-expanded', !!element.querySelector('.dropdown-menu.show'));
                
                            var angularEl = angular.element(element);
                            angularEl.bind('click', function() {
                                if (!!element.querySelector('.dropdown-menu.show')) {
                                    angularEl.attr('aria-expanded', 'true');
                                } else {
                                    angularEl.attr('aria-expanded', 'false');
                                }
                            });
                        });
                    }
                    
                    if(document.querySelector('#NLZoomIn')) {
                        document.querySelector('#NLZoomIn').addEventListener('click', _changeZoom);
                        document.querySelector('#NLZoomIn').setAttribute('role', 'button');
                        document.querySelector('#NLZoomIn').setAttribute('aria-label', _translateFilter('Increase font size'));
                    }
                    if(document.querySelector('#NLZoomOut')) {
                        document.querySelector('#NLZoomOut').addEventListener('click', _changeZoom);
                        document.querySelector('#NLZoomOut').setAttribute('role', 'button');
                        document.querySelector('#NLZoomOut').setAttribute('aria-label', _translateFilter('Decrease font size'));
                    }
                    if(document.querySelector('#NLNormalZoom')) {
                        document.querySelector('#NLNormalZoom').addEventListener('click', _changeZoom);
                    }

                    var nagishLiStyle = document.querySelector('style#NagishLiStyle');
                    if(nagishLiStyle){
                        nagishLiStyle.innerHTML += "div#NagishLiBar *:focus {border: 4px solid red;}";
                    }
                    _changeZoom();
                }, 3000);
            }

            function _changeZoom() {
                if(document.querySelector(':root') && document.querySelector('#NLZoomVal')) {
                    document.querySelector(':root').style.setProperty('--zoom', Number(document.querySelector('#NLZoomVal').innerText.trim()) || 1);
                }
            }

            function _setRotateAttributes() {
                $timeout(function () {
                    var element = document.querySelector('#pleaserotate-backdrop > #pleaserotate-container > #pleaserotate-graphic');
                    !!element && element.setAttribute('aria-hidden', 'true');
                }, 3000);
            }

            function _initLoyaltyClubDrivers() {
                Config.retailer.loyaltyClubs = {};
                angular.forEach((Config.retailer.loyaltyClubDrivers || []), function (driver) {
                    driver.clientConfig.pointsDecimalRound = driver.clientConfig.pointsDecimalRound || 1;
                    if(driver.isActive){
                        for(var key in driver.clientConfig.loyaltyClubs){
                            Config.retailer.loyaltyClubs[key] = driver.clientConfig.loyaltyClubs[key];
                        }
                    }
                });
            }

            if (user.session.userId) {
                $rootScope.refreshObligo = true;
                user.getData().then(function (userData) {
                    if (userData.loyaltyClubCardId) {
                        Util.loyaltyClubAutoRenew(userData);
                    } else {
                        Util.loyaltyClubAutoConnect();
                    }
                });
            }

            var loyaltyClubDriver = !!Config.retailer.loyaltyClubDrivers && !!Config.retailer.loyaltyClubDrivers.length && Config.retailer.loyaltyClubDrivers[0];

            function _checkAccess(access, event, to, params) {
                if ((access === ROUTE_ACCESS.LOGIN || access === ROUTE_ACCESS.USER_VERIFIED) && !user.session.userId) {
                    _preventLocationChange(event, true).then(function() {
                            // if logout in progress, dont show login dialog;
                            if($rootScope.logout){
                                return;
                            }
                            return Util.openLoginDialog();
                    }).then(function () {
                        if (user.session.userId) {
                            $state.go(to.name, params);
                        }
                        // reset rootScopt.logout. Asume done logout process
                        if($rootScope.logout){
                            setTimeout(function(){
                                $rootScope.logout = false;
                            }, 1000);
                        }
                    });

                    return;
                }

                if (access === ROUTE_ACCESS.USER_VERIFIED && !user.isVerified()) {
                    _preventLocationChange(event).then(function() {
                        return UserVerificationDialog.show();
                    }).then(function () {
                        if (user.isVerified()) {
                            $state.go(to.name, params);
                        }
                    });

                    return;
                }

                if (access === ROUTE_ACCESS.USER_VERIFIED && loyaltyClubDriver && loyaltyClubDriver.isActive
                    && loyaltyClubDriver.clientConfig.isRegisterToClubObligation && !user.session.loyaltyClubCardId
                    && !Object.values(cart.lines).find(function (line) {
                        return (line.type === SP_SERVICES.CART_LINE_TYPES.REGISTER_LOYALTY && line.quantity);
                    })) {
                    if (loyaltyClubDriver.clientConfig.extendedLoyaltyClub) {
                        $state.go('app.userEdit.extendedLoyalty');
                        return;
                    }

                    if(cart.lines && (Object.values(cart.lines)).length > 0) {
                        _preventLocationChange(event).then(function() {
                            return Util.showLoyaltyClubDialog();
                        }).then(function () {
                            if(user.session.loyaltyClubCardId || Object.values(cart.lines).find(function (line) {
                                return line.type === SP_SERVICES.CART_LINE_TYPES.REGISTER_LOYALTY;
                            })){
                                $state.go(to.name, params);
                            }
                        });
                    }

                    return;
                }

                if (access === ROUTE_ACCESS.LOGOUT && user.session.userId) {
                    _preventLocationChange(event);
                    return;
                }

                if (_validateOrListenToCartTotal(function() {
                    return cart.sendingSucceeded && (
                        (access === ROUTE_ACCESS.EMPTY_CART && cart.total && cart.total.lines) ||
                        (access === ROUTE_ACCESS.NOT_EMPTY_CART && (!cart.serverCartId || !cart.total || !cart.total.lines)) ||
                        (access === ROUTE_ACCESS.NOT_EMPTY_CART_ACTUAL_LINES && (!cart.serverCartId || !cart.total.actualLines))
                   );
                })) {
                    _preventLocationChange(event);
                    return;
                }

                // the organizationRoleId on the session will only be set on none simple organization users
                if (access === ROUTE_ACCESS.ORGANIZATION_ADMIN &&
                    (!user.session.userId || user.session.organizationRoleId !== ORGANIZATIONS_ROLES.ADMIN)) {
                    _preventLocationChange(event);
                    return;
                }

                //== logged in user that not assigned to organization with type=Obligo
                if (access === ROUTE_ACCESS.NOT_ORGANIZATION_MEMBER && user.session.organizationTypeId === ORGANIZATION_TYPES.OBLIGO) {
                    _preventLocationChange(event);
                    return;
                }

                return true;
            }

            function _validateOrListenToCartTotal(validator) {
                if (!cart.total || !cart.total.calculating) {
                    return validator();
                }

                var listener = $rootScope.$on('cart.totalCalculated', function() {
                    listener();
                    if (validator()) {
                        _preventLocationChange();
                    }
                });
            }

            function _preventLocationChange(event, shouldNotWaitForTimeout) {
                if (event) {
                    event.preventDefault();
                }

                return $q.resolve().then(function () {
                    if (!event || !$state.current || !$state.current.name) {
                        return $state.go('app.home');
                    }
                }).then(function () {
                    // timeout to make sure the browser history changed
                    if (!shouldNotWaitForTimeout) {
                        return $timeout(function () {
                        }, 200);
                    }
                });
            }

            function _checkRouteAccess(event, to, params) {
                if (to && to.data && to.data.routeAccess) {
                    var routeAccess = angular.isArray(to.data.routeAccess) ? to.data.routeAccess : [to.data.routeAccess];
                    for (var i = 0; i < routeAccess.length; i++) {
                        if (_checkAccess(routeAccess[i], event, to, params) !== true) break;
                    }
                }
            }


            $rootScope.$on('ios_iframe_height', function (event, originalEvent) {
                if ($rootScope.windowSize) {
                    $rootScope.windowSize.height = originalEvent.data.height;
                }

                var mainSectionElement = document.querySelector('section.main');
                angular.element(mainSectionElement)
                    .css('height', originalEvent.data.height + 'px')
                    .addClass('ios-scroll-workaround');
            });

            $rootScope.$on('$locationChangeStart', function (event, to, params) {
                var disablePopupOnCurrentRoute = Util.checkPopupDisabledUrls($location.path(),  $rootScope.config.retailer.settings.chooseAreaPopupDisabledUrls);
                if (Config.chooseAreaEvent === SP_SERVICES.CHOOSE_AREA_EVENT.ENTER_SITE && (!Config.branch || !Config.branchAreaId) && !disablePopupOnCurrentRoute) {
                    ChooseAreaDialog.show(true).then(function() {
                        Util.checkForPopupsOnStart();
                    });
                }
            });

            $rootScope.$on('$stateChangeStart', function (event, to, params) {
                _checkRouteAccess(event, to, params);
            });

            $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                $window.prerenderReady = $window.prerenderReady || !searchParams.product && !searchParams.recipe; // for prerender

                toState.data = toState.data || {};
                fromState.data = fromState.data || {};

                if (fromState.data.name) {
                    $html.removeClass(fromState.data.name);
                }

                if (toState.data.name) {
                    $html.addClass(toState.data.name);
                }

                angular.forEach(Object.keys(fromState.data.layout || {}), function (key) {
                    if (fromState.data.layout[key] === false && (!toState.data.layout || toState.data.layout[key] !== false)) {
                        _showLayoutElement(key);
                    }
                });

                angular.forEach(Object.keys(toState.data.layout || {}), function (key) {
                    if ((!fromState.data.layout || fromState.data.layout[key] !== false) && toState.data.layout[key] === false) {
                        _hideLayoutElement(key);
                    }
                });

                $rootScope.$emit('route.change');
            });

            function _showLayoutElement(key) {
                $html.removeClass('hide-' + key);
                $rootScope.$emit(key + '.show');
            }

            function _hideLayoutElement(key) {
                $html.addClass('hide-' + key);
                $rootScope.$emit(key + '.hide');
            }

            Config.initPromise.then(function() {
                _setNagishLiElementAttributes();
                _setRotateAttributes();
                _initLoyaltyClubDrivers();
                return Util.showCookieWallDialog();
            }).then(function() {
                var disablePopupOnCurrentRoute = Util.checkPopupDisabledUrls($location.path(),  $rootScope.config.retailer.settings.chooseAreaPopupDisabledUrls);
                if (Config.chooseAreaEvent === SP_SERVICES.CHOOSE_AREA_EVENT.ENTER_SITE && (!Config.branch || !Config.branchAreaId || $window.sp.frontendData.multiRetailers) && !disablePopupOnCurrentRoute) {
                    ChooseAreaDialog.show(true).then(function() {
                        Util.checkForPopupsOnStart();
                    });
                } else {
                    if (!Config.branch && disablePopupOnCurrentRoute) {
                        Util.setDefaultBranch();
                    }
                    Util.checkForPopupsOnStart();

                    if (Config.chooseAreaEvent === SP_SERVICES.CHOOSE_AREA_EVENT.CART_ACTIVITY && !Config.branchAreaId) {
                        Util.showMultiDomainsOnCartActivity();
                    }
                }

                if (loyaltyClubDriver && loyaltyClubDriver.isLoadLastCartActive && !sessionStorage.getItem('loadCartDialogShown')) {
                    Util.showLoadCartOnCartActivity();
                }

                if (user.session.userId && $rootScope.config.retailer.settings.enablePersonalizedCoupons) {
                    _checkForCouponReminders();
                }
            });

            function _loyaltyActions() {
                if (loyaltyClubDriver && loyaltyClubDriver.clientConfig && loyaltyClubDriver.clientConfig.isRegisterToClubObligation && user.session.userId &&
                    !user.session.loyaltyClubCardId && !Object.values(cart.lines).find(function (line) {
                        return line.type === SP_SERVICES.CART_LINE_TYPES.REGISTER_LOYALTY;
                    })) {
                    Util.showLoyaltyClubDialog();
                }

                if (Config.retailer.settings.isExternalLoyaltyClubRegistrationEnabled === 'true' && user.session.userId && !user.data.foreignId) {
                    Util.showLoyaltyIFrameDialog(Config.retailer.settings.externalLoyaltyClubRegistrationUrl);
                }
            }

            function _checkForCouponReminders() {
                return api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/:bid/users/:uid/coupons',
                    params: {extended: true, countonly: true}
                }).then(function (coupons) {
                    if (coupons && coupons.coupons && coupons.coupons.length) {
                        var showReminderCoupon = false;

                        angular.forEach(coupons.coupons, function (coupon) {
                            if (coupon.showReminder) {
                                showReminderCoupon = coupon;
                            }
                        });

                        if (!!showReminderCoupon) {
                            Util.showCouponReminderDialog(showReminderCoupon);
                        }
                    }
                });
            }

            function _checkCurrentRoute() {
                if (!$state.current) {
                    return;
                }

                _checkRouteAccess(null, $state.current, $state.params);
            }
           

            Util.currentScopeListener($scope, $rootScope.$on('cart.update.complete', _checkCurrentRoute));
            Util.currentScopeListener($scope, $rootScope.$on('login', _checkCurrentRoute));
            Util.currentScopeListener($scope, $rootScope.$on('logout', _checkCurrentRoute));
            Util.currentScopeListener($scope, $rootScope.$on('login', function () {
                if(!$rootScope.registrationInProcess) {
                    _loyaltyActions();
                }
            }));
            var listener = $rootScope.$on('cart.update.complete', function () {
                listener();
                _loyaltyActions();
            });


        }])

         // TODO: move to kikar template
        .controller('MainKikarCtrl', ['spInlineError', 'Dialog', function(spInlineError, Dialog) {
            spInlineError.stickTo = 'bottom';
            Dialog.defaults.closeIconTemplate = '<button class="clickable close-dialog-corner-button no-design" ng-click="$dialog.hide()"><svg focusable="false" aria-labelledby="close_dialog_title" role="img"><title id="close_dialog_title">{{"close" | translate}}</title><use xlink:href="#sp-icons-close-dialog"></use></svg></button>';
        }]);

})(angular, app);
